export const warningModal = {
  textAlign: 'center',
  backgroundColor: '#ffffff',
  padding: '50px 45px 15px 45px',
  borderRadius: '9px',
  height: 'fit-content',
};

export const buttonSection = {
  margin: '40px',
  display: 'flex',
  justifyContent: 'center',
  columnGap: '10px',
};

export const okButton = {
  width: '124px',
  height: '33px',
  borderRadius: '10px',
  fontSize: '12px',
  fontWeight: '500',
  fontFamily: 'Poppins',
  background: 'unset',
  border: '1.5px solid #008000',
  backgroundColor: '#008000',
  color: '#fff',
};
