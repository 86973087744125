import { PdfActions } from 'components/PdfViewer';
import { ColumnsType } from 'components/Tables/BaseTable/types';

const eventLogsType: any = {
  Scheduled_Maintenance: 'Scheduled Maintenance',
  Failure: 'Failure',
  Repaired_On_Site: 'Repaired On Site',
  Repaired_At_SC: 'Repaired At SC',
  Repaired_Under_Warranty: 'Repaired Under Warranty',
};

export const columns: ColumnsType[] = [
  {
    id: 'id',
    label: 'ID',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'type',
    label: 'Type',
    minWidth: 100,
    align: 'left',
    render: ({ type }: any) => `${eventLogsType[type]}`,
  },
  {
    id: 'document',
    sortable: false,
    label: 'Document',
    minWidth: 100,
    align: 'left',
    render: (value: any) => <PdfActions pdfUrl={value.document} />,
  },
  {
    id: 'cost',
    label: 'Cost',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'createdAt',
    label: 'Date',
    minWidth: 100,
    align: 'left',
  },
];
