export const formatNumber = (num?: number) =>
  Number((Number(num) || 0).toFixed(2)).toLocaleString('en-US');

export const formatUsd = (num?: number) =>
  (Number(num) || 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const formatBtc = (num?: number | string, fractionDigits = 6) =>
  Number((Number(num) || 0).toFixed(fractionDigits)).toLocaleString('en-US', {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });

export const formatKaspa = (num?: number | string, fractionDigits = 3) =>
  Number((Number(num) || 0).toFixed(fractionDigits)).toLocaleString('en-US', {
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  });

export const formatCurrency = (value: any, includeDecimals = true) => {
  const roundedValue = includeDecimals
    ? value.toFixed(2)
    : Math.round(value).toString();
  const [integerPart, decimalPart] = roundedValue.split('.');
  const formattedIntegerPart = integerPart.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ',',
  );

  if (value === 0) return '$0.00';

  const result = includeDecimals
    ? `$${formattedIntegerPart}.${decimalPart}`
    : `$${formattedIntegerPart}`;

  if (result === '$0.00' || result === '$0') {
    return '<$0.01';
  }

  return result;
};

export const formatDecimals = (num: number) => {
  if (num === 0) return '0.0000';
  let result = num.toPrecision(4);
  let [base, exponent] = result.split('e');

  base = removeTrailingZeros(base);

  if (exponent) {
    let exp = parseInt(exponent, 10);
    let leadingZerosCount = Math.abs(exp) - 1;
    if (leadingZerosCount > 4) {
      return `0.0<sub>${leadingZerosCount}</sub>${base.replace('.', '')}`;
    } else {
      return `0.${'0'.repeat(leadingZerosCount)}${base.replace('.', '')}`;
    }
  } else {
    return parseFloat(result).toString();
  }
};

export const formatCurrencyWithoutCommas = (value: any) => {
  const [integerPart, decimalPart] = value.toFixed(2).split('.');

  const formattedIntegerPart = integerPart.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    ' ',
  );

  return `${formattedIntegerPart},${decimalPart}`;
};

const removeTrailingZeros = (num: any) => {
  let numStr = num.toString();

  let [integerPart, decimalPart] = numStr.split('.');

  if (decimalPart) {
    decimalPart = decimalPart.replace(/0+$/, '');

    if (decimalPart === '') {
      return integerPart;
    }

    return `${integerPart}.${decimalPart}`;
  }

  return numStr;
};
