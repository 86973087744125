export type AccountInfoState = {
  firstName: string;
  lastName: string;
  companyName?: string;
  email: string;
};

export type CreatePasswordState = {
  password: string;
  confirm: string;
};

export type AccountTypeState = 'individual' | 'company' | undefined;

export enum SignupStepsList {
  accountType = 'accountType',
  accountInfo = 'accountInfo',
  checkEmail = 'checkEmail',
  createPassword = 'createPassword',
  startKyc = 'startKyc',
  // connectBtcWallet = 'connectBtcWallet',
  // connectEthWallet = 'connectEthWallet',
  // welcome = 'welcome',
}
