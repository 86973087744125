export const priceStyles = {
  fontSize: {
    xl: 16,
    lg: 16,
    md: 16,
    sm: 16,
    xs: 16,
  },
};

export const priceContainerStyles = {
  display: 'flex',
  columnGap: 1,
  alignItems: 'baseline',
};

export const filterStyles = {
  display: 'flex',
  columnGap: { xl: 2, lg: 1.5, md: 1, sm: 1, xs: 1 },
  marginTop: 2,
};
