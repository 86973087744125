import { Grid } from '@mui/material';
import AdminContractsTable from 'components/Tables/AdminContractsTable';

export const Contracts = ({
  currency,
  contractsChanged,
  setContractsLoading,
}: any) => {
  return (
    <Grid>
      <AdminContractsTable
        currency={currency}
        contractsChanged={contractsChanged}
      />
    </Grid>
  );
};
