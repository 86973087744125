import alephiumLogo from 'assets/images/coinLogos/alephium-logo.svg';
import bitcoinLogo from 'assets/images/coinLogos/bitcoin-logo.svg';
import bitcoinCashLogo from 'assets/images/coinLogos/bitcoincash-logo.svg';
import kaspaLogo from 'assets/images/coinLogos/kaspa-logo.svg';
import litecoinLogo from 'assets/images/coinLogos/litecoin-logo.svg';
import nervosLogo from 'assets/images/coinLogos/nervos-logo.svg';
import { CurrencyRowContent } from 'components/Tables/CustomerKpiTable/CurrencyRowContent';
import { Currency } from 'shared/utils/enum/coinEnums';

export const getCoinInfo = (
  currency: Currency,
  yesterdayEarning?: any,
  accountBalance?: any,
): any => {
  switch (currency) {
    case Currency.Bitcoin:
      return {
        coinName: 'Bitcoin',
        coinAbb: 'BTC',
        logo: bitcoinLogo,
        rowChildren: (
          <CurrencyRowContent
            coin="BTC"
            currency={currency}
            yesterdayEarning={yesterdayEarning}
            accountBalance={accountBalance}
          />
        ),
      };
    case Currency.BitcoinCash:
      return {
        coinName: 'Bitcoin Cash',
        coinAbb: 'BCH',
        logo: bitcoinCashLogo,
        rowChildren: (
          <CurrencyRowContent
            coin="BCH"
            currency={currency}
            yesterdayEarning={yesterdayEarning}
            accountBalance={accountBalance}
          />
        ),
      };
    case Currency.Litecoin:
      return {
        coinName: 'Litecoin',
        coinAbb: 'LTC',
        logo: litecoinLogo,
        rowChildren: (
          <CurrencyRowContent
            coin="LTC"
            currency={currency}
            yesterdayEarning={yesterdayEarning}
            accountBalance={accountBalance}
          />
        ),
      };
    case Currency.Kaspa:
      return {
        coinName: 'Kaspa',
        coinAbb: 'KAS',
        logo: kaspaLogo,
        rowChildren: (
          <CurrencyRowContent
            coin="KAS"
            currency={currency}
            yesterdayEarning={yesterdayEarning}
            accountBalance={accountBalance}
          />
        ),
      };
    case Currency.Nervos:
      return {
        coinName: 'Nervos',
        coinAbb: 'CKB',
        logo: nervosLogo,
        rowChildren: (
          <CurrencyRowContent
            coin="CKB"
            currency={currency}
            yesterdayEarning={yesterdayEarning}
            accountBalance={accountBalance}
          />
        ),
      };
    case Currency.Alephium:
      return {
        coinName: 'Alephium',
        coinAbb: 'ALPH',
        logo: alephiumLogo,
        rowChildren: (
          <CurrencyRowContent
            coin="ALPH"
            currency={currency}
            yesterdayEarning={yesterdayEarning}
            accountBalance={accountBalance}
          />
        ),
      };
    default:
      throw new Error(`Unknown currency: ${currency}`);
  }
};
