import { Grid } from '@mui/material';
import { SectionHeading } from 'components/SectionHeading';
import { CustomerMiningOpportunitiesTable } from 'components/Tables/MiningOpportunitiesTable';

export const MiningOpportunities = ({ statistics }: any) => {
  return (
    <>
      <SectionHeading name="Mining opportunities" />
      <Grid
        marginTop={0.5}
        marginBottom={5}
        container
        rowSpacing={2}
        columnSpacing={2}
      >
        <CustomerMiningOpportunitiesTable statistics={statistics} />
      </Grid>
    </>
  );
};
