import { Box } from '@mui/material';
import { HearstLogo } from 'components/Icons/HearstLogo';
import { userRedirectionMapping } from 'routes/mappings';
import { useAuth } from 'shared/hooks/useAuth';
import { useVerification } from 'shared/hooks/useVerification';
import { Link } from 'react-router-dom';

export const LogoLink = () => {
  const { user } = useAuth();
  // const { isNewUser, isVerified } = useVerification();

  return (
    <Box sx={{ cursor: 'pointer' }}>
      {/* {isNewUser && !isVerified ? (
        <HearstLogo height={'80px'} width={'140px'} />
      ) : (
        <Link to={userRedirectionMapping[user.role]}>
          <HearstLogo height={'80px'} width={'140px'} />
        </Link>
      )} */}
      <Link to={userRedirectionMapping[user.role]}>
        <HearstLogo height={'80px'} width={'140px'} />
      </Link>
    </Box>
  );
};
