import { useState } from 'react';
import { Box } from '@mui/material';
import UsersTable from 'components/Tables/CustomerUsersTable';
import AdminsTable from 'components/Tables/AdminsTable';
import AffiliatesTable from 'components/Tables/AffiliatesTable';
import AdminContractsTable from 'components/Tables/AdminContractsTable';
import { Layout } from 'components/Layout';
import { SectionHeading } from 'components/SectionHeading';
import { AdminSubAccount } from 'components/Tables/AdminSubAccount';
import { AdminSupplier } from 'components/Tables/AdminSupplier';
import './styles.css';

export const Admin = () => {
  const [userDeleted, setUserDeleted] = useState(false);
  const [userUpdated, setUserUpdated] = useState(false);

  return (
    <Layout>
      <SectionHeading
        name="Admin Dashboard"
        sx={{
          color: '#fff',
          marginBottom: '30px',
        }}
      />
      <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '30px' }}>
        <AdminsTable setUserDeleted={setUserDeleted} />
        <UsersTable
          setUserDeleted={setUserDeleted}
          setUserUpdated={setUserUpdated}
          userUpdated={userUpdated}
          isCustomer={true}
        />
        <UsersTable
          setUserDeleted={setUserDeleted}
          setUserUpdated={setUserUpdated}
          userUpdated={userUpdated}
        />
        <AffiliatesTable
          setUserDeleted={setUserDeleted}
          userUpdated={userUpdated}
        />
      </Box>
      <AdminSubAccount
        userDeleted={userDeleted}
        setUserDeleted={setUserDeleted}
      />
      <AdminSupplier />
      <AdminContractsTable
        allContracts
        userDeleted={userDeleted}
        setUserDeleted={setUserDeleted}
      />
    </Layout>
  );
};
