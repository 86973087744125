import Axios, { AxiosRequestConfig, ResponseType } from 'axios';
import { envConfig } from 'config/env';
import { NavigateFunction } from 'react-router-dom';
import { routesMapping } from 'routes/mappings';
import { accessToken } from 'shared/constants/localStorage';

class HttpService {
  _axios = Axios.create({
    baseURL: envConfig.API_BASE_URL,
  });

  setupAxiosInterceptors = (navigate: NavigateFunction) => {
    this._axios.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem(accessToken);

        config.headers.Authorization = `Bearer ${token}`;
        return config;
      },
      (error) => Promise.reject(error),
    );

    this._axios.interceptors.response.use(
      (config) => config,
      (error) => {
        const status = error?.response?.status;

        if (status === 401) {
          localStorage.removeItem(accessToken);
          navigate(routesMapping.login);
        }

        return Promise.reject(error);
      },
    );
  };

  get = async (
    url: string,
    params: any = {},
    responseType: ResponseType = 'json',
  ) =>
    await this.request({
      method: 'get',
      url,
      params,
      responseType,
    });

  post = async (url: string, data?: any) =>
    await this.request({ method: 'post', url, data });

  put = async (url: string, data: any) =>
    await this.request({ method: 'put', url, data });

  delete = async (url: string) => await this.request({ method: 'delete', url });

  private async request(config: AxiosRequestConfig): Promise<any> {
    try {
      const response = await this._axios.request(config);
      return response.data;
    } catch (error: any) {
      if (error.response) throw error.response.data;
      throw error;
    }
  }
}

export default new HttpService();
