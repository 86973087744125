import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Typography,
} from '@mui/material';
import { ControlledTextField } from 'components/ControlledInputs/TextField';
import {
  container,
  content,
  formTitle,
  submitButton,
} from 'components/Forms/styles';
import { SvgComponentHide } from 'components/Icons/Hide';
import { SvgComponentSee } from 'components/Icons/See';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAuth } from 'shared/hooks/useAuth';
import { useNotification } from 'shared/hooks/useNotification';
import UsersService from 'shared/services/users.service';
import { UserRole } from 'shared/utils/enum/UserEnum';
import * as yup from 'yup';

const AffiliateSubAccountForm = ({ editData, onFinish, setShowModal }: any) => {
  const { user } = useAuth();
  const [showPassword, setShowPassword] = useState(false);
  const { showSnackbar } = useNotification();

  const schema = yup.object().shape({
    email: yup.string().required('Email is a required field').trim(),
    username: yup.string().required('Username is a required field'),
    hashratePercent: yup.string().required('Hashrate is a required field'),
    affiliatePercent: yup.string().required('Upfront is a required field'),
    password: editData?.id
      ? yup.string()
      : yup
          .string()
          .required('Password is a required field')
          .min(8, 'Password must be at least 8 characters'),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit: any = async (userData: any): Promise<void> => {
    try {
      const payload = {
        ...userData,
        ownerId: user.id,
        companyName: user.companyName,
        role: UserRole.AFFILIATE_SUBACCOUNT,
      };

      if (editData) {
        await UsersService.updateAffiliateSubAcc(editData.id, userData);
        showSnackbar('Successfully Edited', 'success');
      } else {
        await UsersService.createUser(payload);
        showSnackbar('Successfully Created', 'success');
      }
      onFinish();
    } catch (err: any) {
      showSnackbar(err.error, 'error');
    } finally {
      setShowModal(false);
    }
  };

  return (
    <Box sx={container}>
      <form style={content} onSubmit={handleSubmit(onSubmit)}>
        <Typography sx={formTitle}>
          {editData ? 'Edit' : 'Add'} Subaccount
        </Typography>
        <ControlledTextField
          required
          control={control}
          defaultValue={editData?.username ?? ''}
          name="username"
          label="Username"
          error={errors}
          placeholder="Username"
        />
        <ControlledTextField
          required
          control={control}
          defaultValue={editData?.email ?? ''}
          name="email"
          label="Email"
          error={errors}
          placeholder="name.example@gmail.com"
        />
        {!editData?.id && (
          <ControlledTextField
            control={control}
            defaultValue=""
            name="password"
            label="Password"
            placeholder="Password"
            error={errors}
            required
            type={showPassword ? 'text' : 'password'}
            textFieldProps={{
              InputProps: {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleClickShowPassword}>
                      {showPassword ? (
                        <SvgComponentSee />
                      ) : (
                        <SvgComponentHide />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              },
            }}
          />
        )}
        <ControlledTextField
          required
          control={control}
          defaultValue={editData?.upfront ?? ''}
          name="affiliatePercent"
          label="Upfront %"
          error={errors}
          placeholder="Upfront"
        />
        <ControlledTextField
          required
          control={control}
          defaultValue={editData?.hashrate ?? ''}
          name="hashratePercent"
          label="Hashrate %"
          error={errors}
          placeholder="Hashrate"
        />
        <Box className="buttons">
          <Button sx={submitButton} type="submit">
            {editData ? 'Save Changes' : 'Add User'}
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default AffiliateSubAccountForm;
