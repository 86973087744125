import HttpService from './http.service';

class MachinesService {
  endpoint: string = 'machines';

  getMachinesByContract = async (contractId: string, params: any) =>
    await HttpService.get(`${this.endpoint}/contract/${contractId}`, params);

  getMachine = async (machineId: string) =>
    await HttpService.get(`${this.endpoint}/${machineId}`);

  getMachineEventLogs = async (machineId: string, params: any) =>
    await HttpService.get(`${this.endpoint}/event-logs/${machineId}`, params);

  createMachine = async (payload: any) =>
    await HttpService.post(`${this.endpoint}`, payload);

  createMachinesFromFile = async (payload: any) =>
    await HttpService.post(`${this.endpoint}/file`, payload);

  createMachineEventLog = async (payload: any) =>
    await HttpService.post(`${this.endpoint}/event-log`, payload);

  updateMachine = async (machineId: string, payload: any) =>
    await HttpService.put(`${this.endpoint}/${machineId}`, payload);

  updateMachineEventLog = async (eventLogId: string, payload: any) =>
    await HttpService.put(`${this.endpoint}/event-log/${eventLogId}`, payload);

  deleteMachine = async (machineId: string) =>
    await HttpService.delete(`${this.endpoint}/${machineId}`);

  deleteMachineEventLog = async (eventLogId: string) =>
    await HttpService.delete(`${this.endpoint}/event-log/${eventLogId}`);
}

export default new MachinesService();
