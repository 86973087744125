export enum Currency {
  Bitcoin = 'Bitcoin',
  BitcoinCash = 'BitcoinCash',
  Litecoin = 'Litecoin',
  Kaspa = 'Kaspa',
  Nervos = 'Nervos',
  Alephium = 'Alephium',
}

export enum HardwareCurrency {
  Bitcoin = 'Bitcoin',
  Litecoin = 'Litecoin',
  Kaspa = 'Kaspa',
  Nervos = 'Nervos',
  Alephium = 'Alephium',
}
