import { useMemo } from 'react';
import { Navigate } from 'react-router-dom';
import { Progress } from 'components/Progress';
import { UserRole } from 'shared/utils/enum/UserEnum';
import { useAuth } from 'shared/hooks/useAuth';
import { userRedirectionMapping } from 'routes/mappings';

interface IProps {
  children: JSX.Element;
  roles?: Array<UserRole>;
}

export const ProtectedRoute = (props: IProps) => {
  const { children, roles = [UserRole.CUSTOMER] } = props;

  const { user, userStatus } = useAuth();

  const isAuthenticated = useMemo(
    () => userStatus === 'done' && roles.includes(user.role),
    [userStatus, user, roles],
  );

  if (userStatus === 'loading') return <Progress className="center" />;

  return isAuthenticated ? (
    children
  ) : (
    <Navigate to={userRedirectionMapping[user.role]} replace />
  );
};
