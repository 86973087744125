import { Controller } from 'react-hook-form';
import {
  Box,
  FormHelperText,
  MenuItem,
  Select as MuiSelect,
  Typography,
} from '@mui/material';

export const ControlledSelect = (props: any) => {
  const {
    control,
    disabled,
    name,
    label,
    error,
    required,
    options,
    defaultValue,
  } = props;

  return (
    <Box width="100%">
      <Box display="flex" columnGap={0.5} marginY={0.5}>
        <Typography
          fontWeight="500"
          fontSize="16px"
          lineHeight="15px"
          color="#666666"
          marginTop="10px"
        >
          {label}
        </Typography>
        {required && (
          <Typography fontSize="15px" color="#ff4242">
            *
          </Typography>
        )}
      </Box>
      <Controller
        defaultValue={defaultValue}
        name={name}
        control={control}
        render={({ field }) => (
          <>
            <MuiSelect
              disabled={disabled}
              fullWidth
              sx={{
                border: error[name] && '1px solid #ff4242 !important',
                marginTop: '8px',
                marginBottom: '4px',
                borderRadius: '8px',
              }}
              {...field}
            >
              {options.map((option: any, index: number) => (
                <MenuItem value={option.value} key={`select-${index}`}>
                  {option.name}
                </MenuItem>
              ))}
            </MuiSelect>
            <FormHelperText sx={{ marginX: '14px' }}>
              {!!error[name] ? error[name]?.message : ''}
            </FormHelperText>
          </>
        )}
      />
    </Box>
  );
};
