import { Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useAuth } from 'shared/hooks/useAuth';
import { useFetch } from 'shared/hooks/useFetch';
import usersService from 'shared/services/users.service';

export const Welcome = () => {
  const { customerId } = useParams();
  const { user } = useAuth();

  const [customer] = useFetch(() =>
    customerId
      ? Promise.any([
          usersService.getAffiliateCustomer(customerId),
          usersService.getUser(customerId),
        ])
      : undefined,
  );

  return (
    <Typography
      sx={{
        fontSize: {
          xs: 32,
          sm: 40,
          md: 48,
          lg: 52,
        },
      }}
      fontWeight={500}
      marginBottom={5}
      color="#fff"
      variant="h2"
    >
      Welcome back, {customer?.username || user.username}
    </Typography>
  );
};
