import { Box } from '@mui/material';
import AdminContractsTable from 'components/Tables/AdminContractsTable';
import React, { useState } from 'react';

const Contracts = () => {
  const [userDeleted, setUserDeleted] = useState(false);

  return (
    <Box>
      <AdminContractsTable
        allContracts
        userDeleted={userDeleted}
        setUserDeleted={setUserDeleted}
      />
    </Box>
  );
};

export default Contracts;
