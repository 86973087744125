import { submitButton } from 'components/Forms/styles';
import { useEffect, useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import moment from 'moment';
import { Box, Button, Typography } from '@mui/material';
import { CountryDropdown } from 'react-country-region-selector';
import { IFormInput } from './types';
import ContractsService from 'shared/services/contracts.service';
import { useNotification } from 'shared/hooks/useNotification';
import { ControlledTextField } from 'components/ControlledInputs/TextField';
import { ControlledDatePicker } from 'components/ControlledInputs/DatePicker';
import { ControlledSelect } from 'components/ControlledInputs/Select';
import { BitmainLogo } from 'components/Icons/Bitmain';
import { WhatsminerLogo } from 'components/Icons/Whatsminer';
import UsersService from 'shared/services/users.service';
import './index.css';
import { Currency } from 'shared/utils/enum/coinEnums';
import { handleCurrencyName } from 'shared/utils/currency';
import { IceRiverLogo } from 'components/Icons/IceRiver';

const formatDate = (date: string) =>
  moment(date, 'DD/MM/yyyy').format('YYYY-MM-DD');

const sections = {
  display: 'flex',
  width: '100%',
  columnGap: 2,
  flexDirection: {
    xs: 'column',
    sm: 'row',
    md: 'row',
    lg: 'row',
  },
};

const schema = yup.object().shape({
  id: yup.string(),
  user: yup
    .string()
    // .email()
    .required('Customer email is a required field'),
  machineType: yup.string().required('Machine type is a required field'),
  machineModel: yup.string().required('Machine model is a required field'),
  name: yup.string().required('Contract name is a required field'),
  machineWatt: yup.number().required(),
  machineTH: yup.number().required(),
  electricityCost: yup.number().required(),
  minersCost: yup.number().required(),
  hostingCost: yup.number().required(),
  location: yup.string().required('Contract Country is a required field'),
  hostingCompany: yup.string().required('Hosting Company is a required field'),
  machineSupplier: yup
    .string()
    .required('Hosting Provider is a required field'),
  totalInvestment: yup.number().required(),
  hearstUpfront: yup.number().required(),
  affiliateUpfront: yup.number().required(),
  yearToCapitalConstitution: yup
    .number()
    .required('Year To Capital Constitution is a required field'),
  subAccountUserId: yup
    .string()
    .required('Sub Account User ID is a required field'),
  subAccountApiKey: yup
    .string()
    .required('Sub Account API Key is a required field'),
  subAccountApiSecret: yup.string().notRequired(),
  contractStartingDate: yup.string(),
  timeToPlug: yup.string(),
  plugDate: yup.string(),
  numberOfMachines: yup.number().required(),
  contractStatus: yup.string().required('Contract Status a required field'),
  contractType: yup.string().required('Contract Type a required field'),
  monthlyElectricityCost: yup.string().required(),
  currency: yup.string(),
});

export const ContractForm = ({
  setShowModal,
  editData,
  onFinish,
}: any): JSX.Element => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>({ resolver: yupResolver(schema) });

  const { showSnackbar } = useNotification();
  const [suppliers, setSuppliers] = useState<Array<any>>([]);
  const [customers, setCustomers] = useState<Array<any>>([]);

  const getCustomers = async () => {
    try {
      const { users } = await UsersService.getCustomers();
      return setCustomers(
        users.map((s: any) => ({ value: s.id, name: s.email })),
      );
    } catch (err: any) {
      console.log(err);
    }
  };

  const getSuppliers = async () => {
    const { users } = await UsersService.getAllSuppliers();
    return setSuppliers(
      users.map((s: any) => ({ value: s.id, name: s.username })),
    );
  };

  useEffect(() => {
    getSuppliers();
    getCustomers();
  }, []);

  const currencies = Object.values(Currency).map((value) => ({
    value,
    name: handleCurrencyName(value),
  }));

  const onSubmit = async (contractData: any) => {
    try {
      const contract = {
        ...contractData,
        plugDate: formatDate(contractData.plugDate),
        timeToPlug: formatDate(contractData.timeToPlug),
        contractStartingDate: formatDate(contractData.contractStartingDate),
      };
      if (editData && editData.id) {
        await ContractsService.updateContract(editData.id, contract);
        showSnackbar('Successfully Edited', 'success');
      } else {
        await ContractsService.createContract(contract);
        showSnackbar('Successfully Created', 'success');
      }
      onFinish();
    } catch (err: any) {
      showSnackbar(err.error, 'error');
    } finally {
      setShowModal(false);
    }
  };

  const contractStatusOptions = [
    { name: 'Signed', value: 'Signed' },
    { name: 'Deployed', value: 'Deployed' },
    { name: 'Deploying', value: 'Deploying' },
  ];

  const contractTypeOptions = [
    { name: 'Antpool', value: 'Antpool' },
    { name: 'BTCPool', value: 'Btcpool' },
    { name: '21Pool', value: 'TwentyOnepool' },
  ];

  const machineTypeOptions = [
    { name: <BitmainLogo />, value: 'Bitmain' },
    { name: <WhatsminerLogo />, value: 'Whatsminer' },
    { name: <IceRiverLogo />, value: 'IceRiver' },
  ];

  return (
    <>
      <Box
        sx={{
          padding: 5,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <Typography className="contract-from-title-c">
          {editData?.id ? `Edit Contract ${editData.name}` : 'Add Contract'}
        </Typography>
        <form
          className="contract-form-c"
          onSubmit={handleSubmit(onSubmit)}
          id="nameform"
        >
          <Box sx={sections}>
            <ControlledSelect
              control={control}
              name="user"
              defaultValue={editData?.user?.id || ''}
              label="Customer"
              error={errors}
              disabled={!!editData?.user?.id}
              required
              options={customers}
            />
            <ControlledSelect
              control={control}
              name="machineType"
              defaultValue={editData?.machineType ?? ''}
              label="Machine Type"
              error={errors}
              required
              options={machineTypeOptions}
            />
          </Box>
          <Box sx={sections}>
            <ControlledTextField
              required
              control={control}
              defaultValue={editData?.machineModel || ''}
              name="machineModel"
              label="Machine Model"
              error={errors}
              placeholder="Machine model"
            />
            <ControlledTextField
              control={control}
              defaultValue={editData?.numberOfMachines || 0}
              name="numberOfMachines"
              label="Number Of Machines"
              error={errors}
              placeholder="Number Of Machines"
              type="number"
            />
          </Box>
          <Box sx={sections}>
            <ControlledTextField
              control={control}
              defaultValue={editData?.machineWatt ?? 0}
              name="machineWatt"
              label="Machine Power Consumption (Watt)"
              error={errors}
              placeholder="Machine Power Consumption (Watt)"
              type="number"
            />
            <ControlledTextField
              control={control}
              defaultValue={editData?.machineTH ?? 0}
              name="machineTH"
              label="Machine Computation Power (TH)"
              error={errors}
              placeholder="Machine Computation Power (TH)"
              type="number"
            />
          </Box>
          <Box sx={sections}>
            <ControlledTextField
              control={control}
              defaultValue={editData?.hostingCost ?? 0}
              name="hostingCost"
              label="Cost Of Upfront($)"
              error={errors}
              placeholder="Cost Of Hosting Upfront"
              type="number"
            />
            <ControlledTextField
              control={control}
              defaultValue={editData?.minersCost ?? 0}
              name="minersCost"
              label="Cost Of Miners Installed($)"
              error={errors}
              placeholder="Cost Of Miners Installed"
              type="number"
            />
          </Box>
          <Box sx={sections}>
            <ControlledTextField
              control={control}
              defaultValue={editData?.electricityCost ?? 0}
              name="electricityCost"
              label="Cost Of Electricity & Hosting ($/KwH)"
              error={errors}
              placeholder="Cost Of Electricity & Hosting ($/KwH)"
              type="number"
              width={'75%'}
            />
            <div className="wrapInput">
              <p className="countriesLabel">
                Contract Country <span className="asterisk">*</span>
              </p>
              <Controller
                name="location"
                control={control}
                defaultValue={editData?.location ?? ''}
                render={({ field }) => (
                  <div className="wrapInput">
                    <CountryDropdown
                      name="location"
                      valueType="short"
                      onChange={field.onChange}
                      value={field.value}
                      classes={`countries ${
                        errors.location ? 'country-error' : 'country-regular'
                      }`}
                      defaultOptionLabel="Contract Country"
                    />
                    {errors.location && (
                      <p
                        style={{
                          color: 'red',
                          alignSelf: 'flex-start',
                          fontWeight: 400,
                          fontSize: '0.75rem',
                          margin: '3px 14px 0 14px',
                        }}
                      >
                        {errors.location.message}
                      </p>
                    )}
                  </div>
                )}
              />
            </div>
          </Box>
          <Box sx={sections}>
            <ControlledTextField
              control={control}
              defaultValue={editData?.hostingCompany ?? ''}
              name="hostingCompany"
              label="Hosting Company"
              error={errors}
              placeholder="Hosting Company"
              required
            />
            <ControlledSelect
              control={control}
              name="machineSupplier"
              defaultValue={editData?.machineSupplier?.id ?? ''}
              label="Hosting Provider"
              error={errors}
              required
              options={suppliers}
            />
          </Box>
          <Box sx={sections}>
            <ControlledTextField
              control={control}
              defaultValue={editData?.totalInvestment ?? 0}
              name="totalInvestment"
              type="number"
              label="Total Investment($)"
              error={errors}
              placeholder="Total Investment"
            />
          </Box>
          <Box sx={sections}>
            <ControlledTextField
              control={control}
              defaultValue={editData?.hearstUpfront ?? 0}
              name="hearstUpfront"
              label="Hearst Upfront($)"
              error={errors}
              placeholder="Hearst Upfront"
              type="number"
            />
            <ControlledTextField
              control={control}
              defaultValue={editData?.affiliateUpfront ?? 0}
              name="affiliateUpfront"
              label="Affiliate Upfront"
              error={errors}
              placeholder="Affiliate Upfront"
              type="number"
            />
          </Box>
          <div className="contractSectionLastElement">
            <ControlledTextField
              control={control}
              defaultValue={editData?.yearToCapitalConstitution ?? 0}
              name="yearToCapitalConstitution"
              label="Year To Capital Constitution"
              error={errors}
              placeholder="Year To Capital Constitution"
              type="number"
            />
            <ControlledTextField
              control={control}
              defaultValue={editData?.name ?? ''}
              name="name"
              label="Contract Name"
              error={errors}
              placeholder="Contract Name"
              type="text"
            />
            <Box sx={{ width: '100%' }}>
              <ControlledSelect
                control={control}
                name="currency"
                defaultValue={editData?.currency ?? 'Bitcoin'}
                label="Cryptocurrency"
                error={errors}
                options={currencies}
              />
            </Box>
          </div>
          <Box sx={sections}>
            <ControlledSelect
              control={control}
              name="contractType"
              defaultValue={editData?.contractType ?? ''}
              label="Pool"
              error={errors}
              required
              options={contractTypeOptions}
            />
            <ControlledSelect
              control={control}
              name="contractStatus"
              defaultValue={editData?.contractStatus ?? ''}
              label="Contract Status"
              error={errors}
              required
              options={contractStatusOptions}
            />
          </Box>
          <ControlledTextField
            control={control}
            defaultValue={editData?.subAccountUserId ?? ''}
            name="subAccountUserId"
            label="Sub Account User Id"
            error={errors}
            placeholder="Sub Account User Id"
            required
          />
          <ControlledTextField
            control={control}
            defaultValue={editData?.subAccountApiKey ?? ''}
            name="subAccountApiKey"
            label="Sub Account API Key"
            error={errors}
            placeholder="Sub Account API Key"
            required
          />
          <ControlledTextField
            control={control}
            defaultValue={editData?.subAccountApiSecret ?? ''}
            name="subAccountApiSecret"
            label="Sub Account API Secret"
            error={errors}
            placeholder="Sub Account API Secret"
          />
          <ControlledDatePicker
            label="Contract Starting Date"
            name="contractStartingDate"
            placeholder="dd/mm/yyyy"
            dateFormat="DD/MM/yyyy"
            control={control}
            defaultValue={
              editData?.contractStartingDate
                ? moment(editData?.contractStartingDate).format('DD/MM/yyyy')
                : ''
            }
            error={errors}
          />
          <ControlledDatePicker
            label="Time to Plug"
            name="timeToPlug"
            placeholder="dd/mm/yyyy"
            dateFormat="DD/MM/yyyy"
            control={control}
            defaultValue={
              editData?.timeToPlug
                ? moment(editData?.timeToPlug).format('DD/MM/yyyy')
                : ''
            }
            error={errors}
          />
          <ControlledDatePicker
            label="Plug Date"
            name="plugDate"
            placeholder="dd/mm/yyyy"
            dateFormat="DD/MM/yyyy"
            control={control}
            defaultValue={
              editData?.plugDate
                ? moment(editData?.plugDate).format('DD/MM/yyyy')
                : ''
            }
            error={errors}
          />
          <ControlledTextField
            control={control}
            defaultValue={editData?.monthlyElectricityCost ?? 0}
            name="monthlyElectricityCost"
            label="Monthly electricity cost (+/- 5%)"
            error={errors}
            placeholder="Monthly electricity cost (+/- 5%)"
            required
            type="number"
          />
        </form>
        <Button sx={submitButton} form="nameform" type="submit">
          {editData && !editData.id ? 'Add Contract' : 'Save'}
        </Button>
      </Box>
    </>
  );
};
