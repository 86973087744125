import AdminCustomersDetails from 'pages/Admin/AdminCustomerDetails';
import { AdminOperation } from 'pages/Admin/AdminOperation';
import { AffiliateCustomerDetails } from 'pages/Affiliate/AffiliateCustomerDetails';
import { AffiliateSimulation } from 'pages/Affiliate/AffiliateSimulation';
import { SignUp } from 'pages/SignUp';
import { Navigate, Route, Routes } from 'react-router-dom';
import { AffiliateOperation } from 'pages/Affiliate/AffiliateOperation';
import { AffiliateTransactions } from 'pages/Affiliate/AffiliateTransactions';
import { TeamMembers } from 'pages/Affiliate/TeamMembers';
import { Login } from 'pages/Login';
import { Admin } from 'pages/Admin/Management';
import { Opportunities } from 'pages/Admin/Opportunities';
import { Operation } from 'pages/Customer/Operation';
import { CustomerHome } from 'pages/Customer/CustomerHome';
import CustomerContract from 'pages/Customer/Operation/Contracts/Contract';
import Transaction from 'pages/Customer/Transaction';
import Invoicing from 'pages/Customer/Invoicing';
import { WalletDetails } from 'pages/Customer/Wallet';
import ContractData from 'pages/ContractData';
import { UserRole } from 'shared/utils/enum/UserEnum';
import SubAccount from 'pages/SubAccount';
import Machines from 'pages/Machines';
import ForgotPassword from 'pages/ForgotPassword';
import { Supplier } from 'pages/Supplier';
import { Simulation } from 'pages/Admin/Simulation';
import { Verification } from 'pages/Verification';
import { Profile } from 'pages/Profile';
import { useAuth } from 'shared/hooks/useAuth';
import { AuthRoute, ProtectedRoute } from './Guards';
import { routesMapping, userRedirectionMapping } from './mappings';
import { AffiliateCustomerCheck } from 'components/AffiliateCustomerCheck';
import { verificationEligibleUsers } from 'contexts/VerificationContext/users';
import { AffiliateCustomers } from 'pages/Affiliate/AffiliateCustomers';

type RouteType = {
  path: string;
  element: JSX.Element;
};

const routes: RouteType[] = [
  {
    path: `${routesMapping.forgotPassword}`,
    element: (
      <AuthRoute>
        <ForgotPassword />
      </AuthRoute>
    ),
  },
  {
    path: routesMapping.login,
    element: (
      <AuthRoute>
        <Login />
      </AuthRoute>
    ),
  },
  {
    path: routesMapping.signUp,
    element: (
      <AuthRoute>
        <SignUp />
      </AuthRoute>
    ),
  },
  {
    path: routesMapping.customerHome,
    element: (
      <ProtectedRoute roles={[UserRole.CUSTOMER, UserRole.SUBACCOUNT]}>
        <CustomerHome />
      </ProtectedRoute>
    ),
  },
  {
    path: routesMapping.customerOperation,
    element: (
      <ProtectedRoute roles={[UserRole.CUSTOMER, UserRole.SUBACCOUNT]}>
        <Operation />
      </ProtectedRoute>
    ),
  },
  {
    path: routesMapping.customerContract,
    element: (
      <ProtectedRoute roles={[UserRole.CUSTOMER, UserRole.SUBACCOUNT]}>
        <CustomerContract />
      </ProtectedRoute>
    ),
  },
  {
    path: routesMapping.customerTransaction,
    element: (
      <ProtectedRoute roles={[UserRole.CUSTOMER, UserRole.SUBACCOUNT]}>
        <Transaction />
      </ProtectedRoute>
    ),
  },
  {
    path: routesMapping.customerInvoicing,
    element: (
      <ProtectedRoute roles={[UserRole.CUSTOMER, UserRole.SUBACCOUNT]}>
        <Invoicing />
      </ProtectedRoute>
    ),
  },
  {
    path: routesMapping.customerWallet,
    element: (
      <ProtectedRoute roles={[UserRole.CUSTOMER, UserRole.SUBACCOUNT]}>
        <WalletDetails />
      </ProtectedRoute>
    ),
  },
  {
    path: routesMapping.subAccount,
    element: (
      <ProtectedRoute roles={[UserRole.CUSTOMER]}>
        <SubAccount />
      </ProtectedRoute>
    ),
  },
  {
    path: routesMapping.adminManagement,
    element: (
      <ProtectedRoute roles={[UserRole.ADMIN]}>
        <Admin />
      </ProtectedRoute>
    ),
  },
  {
    path: routesMapping.adminOpportunities,
    element: (
      <ProtectedRoute roles={[UserRole.ADMIN]}>
        <Opportunities />
      </ProtectedRoute>
    ),
  },
  {
    path: routesMapping.adminSimulation,
    element: (
      <ProtectedRoute roles={[UserRole.ADMIN]}>
        <Simulation />
      </ProtectedRoute>
    ),
  },
  {
    path: routesMapping.adminViewCustomerHome,
    element: (
      <ProtectedRoute roles={[UserRole.ADMIN]}>
        <CustomerHome />
      </ProtectedRoute>
    ),
  },
  {
    path: routesMapping.adminViewCustomerOperation,
    element: (
      <ProtectedRoute roles={[UserRole.ADMIN]}>
        <Operation />
      </ProtectedRoute>
    ),
  },
  {
    path: routesMapping.adminViewCustomerTransaction,
    element: (
      <ProtectedRoute roles={[UserRole.ADMIN]}>
        <Transaction />
      </ProtectedRoute>
    ),
  },
  {
    path: routesMapping.adminViewCustomerInvoicing,
    element: (
      <ProtectedRoute roles={[UserRole.ADMIN]}>
        <Invoicing />
      </ProtectedRoute>
    ),
  },
  {
    path: `${routesMapping.adminViewCustomerContract}`,
    element: (
      <ProtectedRoute roles={[UserRole.ADMIN]}>
        <ContractData isCustomer />
      </ProtectedRoute>
    ),
  },
  {
    path: routesMapping.affiliateViewCustomerContract,
    element: (
      <ProtectedRoute
        roles={[UserRole.AFFILIATE, UserRole.AFFILIATE_SUBACCOUNT]}
      >
        <ContractData isCustomer />
      </ProtectedRoute>
    ),
  },
  {
    path: `${routesMapping.adminViewCustomerWallet}`,
    element: (
      <ProtectedRoute roles={[UserRole.ADMIN]}>
        <WalletDetails />
      </ProtectedRoute>
    ),
  },
  {
    path: `${routesMapping.adminContract}`,
    element: (
      <ProtectedRoute roles={[UserRole.ADMIN]}>
        <ContractData />
      </ProtectedRoute>
    ),
  },
  {
    path: `${routesMapping.adminUser}`,
    element: (
      <ProtectedRoute roles={[UserRole.ADMIN]}>
        <AdminCustomersDetails />
      </ProtectedRoute>
    ),
  },
  {
    path: `${routesMapping.adminMachine}`,
    element: (
      <ProtectedRoute roles={[UserRole.ADMIN]}>
        <Machines />
      </ProtectedRoute>
    ),
  },
  {
    path: routesMapping.supplier,
    element: (
      <ProtectedRoute roles={[UserRole.SUPPLIER]}>
        <Supplier />
      </ProtectedRoute>
    ),
  },
  {
    path: routesMapping.verification,
    element: (
      <ProtectedRoute roles={verificationEligibleUsers}>
        <Verification />
      </ProtectedRoute>
    ),
  },
  {
    path: routesMapping.profile,
    element: (
      <ProtectedRoute
        roles={[
          UserRole.CUSTOMER,
          UserRole.ADMIN,
          UserRole.AFFILIATE,
          UserRole.SUPPLIER,
        ]}
      >
        <Profile />
      </ProtectedRoute>
    ),
  },
  {
    path: routesMapping.adminOperation,
    element: (
      <ProtectedRoute roles={[UserRole.ADMIN]}>
        <AdminOperation />
      </ProtectedRoute>
    ),
  },
  {
    path: routesMapping.affiliateOperation,
    element: (
      <ProtectedRoute
        roles={[UserRole.AFFILIATE, UserRole.AFFILIATE_SUBACCOUNT]}
      >
        <AffiliateOperation />
      </ProtectedRoute>
    ),
  },
  {
    path: routesMapping.affiliateTeamMembers,
    element: (
      <ProtectedRoute
        roles={[UserRole.AFFILIATE, UserRole.AFFILIATE_SUBACCOUNT]}
      >
        <TeamMembers />
      </ProtectedRoute>
    ),
  },
  {
    path: routesMapping.affiliateTransactions,
    element: (
      <ProtectedRoute
        roles={[UserRole.AFFILIATE, UserRole.AFFILIATE_SUBACCOUNT]}
      >
        <AffiliateTransactions />
      </ProtectedRoute>
    ),
  },
  {
    path: routesMapping.affiliateViewCustomerHome,
    element: (
      <ProtectedRoute
        roles={[UserRole.AFFILIATE, UserRole.AFFILIATE_SUBACCOUNT]}
      >
        <AffiliateCustomerCheck>
          <CustomerHome />
        </AffiliateCustomerCheck>
      </ProtectedRoute>
    ),
  },
  {
    path: `${routesMapping.affiliateViewCustomerWallet}`,
    element: (
      <ProtectedRoute
        roles={[UserRole.AFFILIATE, UserRole.AFFILIATE_SUBACCOUNT]}
      >
        <AffiliateCustomerCheck>
          <WalletDetails />
        </AffiliateCustomerCheck>
      </ProtectedRoute>
    ),
  },
  {
    path: routesMapping.affiliateViewCustomerTransaction,
    element: (
      <ProtectedRoute
        roles={[UserRole.AFFILIATE, UserRole.AFFILIATE_SUBACCOUNT]}
      >
        <AffiliateCustomerCheck>
          <Transaction />
        </AffiliateCustomerCheck>
      </ProtectedRoute>
    ),
  },
  {
    path: routesMapping.affiliateViewCustomerInvoicing,
    element: (
      <ProtectedRoute
        roles={[UserRole.AFFILIATE, UserRole.AFFILIATE_SUBACCOUNT]}
      >
        <AffiliateCustomerCheck>
          <Invoicing />
        </AffiliateCustomerCheck>
      </ProtectedRoute>
    ),
  },
  {
    path: routesMapping.affiliateCustomerDetails,
    element: (
      <ProtectedRoute
        roles={[UserRole.AFFILIATE, UserRole.AFFILIATE_SUBACCOUNT]}
      >
        <AffiliateCustomerCheck>
          <AffiliateCustomerDetails />
        </AffiliateCustomerCheck>
      </ProtectedRoute>
    ),
  },
  {
    path: routesMapping.affiliateViewCustomerOperation,
    element: (
      <ProtectedRoute
        roles={[UserRole.AFFILIATE, UserRole.AFFILIATE_SUBACCOUNT]}
      >
        <AffiliateCustomerCheck>
          <Operation />
        </AffiliateCustomerCheck>
      </ProtectedRoute>
    ),
  },
  {
    path: routesMapping.affiliateSimulation,
    element: (
      <ProtectedRoute
        roles={[UserRole.AFFILIATE, UserRole.AFFILIATE_SUBACCOUNT]}
      >
        <AffiliateSimulation />
      </ProtectedRoute>
    ),
  },
  {
    path: routesMapping.affiliateCustomers,
    element: (
      <ProtectedRoute
        roles={[UserRole.AFFILIATE, UserRole.AFFILIATE_SUBACCOUNT]}
      >
        <AffiliateCustomers />
      </ProtectedRoute>
    ),
  },
  {
    path: `${routesMapping.affiliateContract}`,
    element: (
      <ProtectedRoute
        roles={[UserRole.AFFILIATE, UserRole.AFFILIATE_SUBACCOUNT]}
      >
        <ContractData />
      </ProtectedRoute>
    ),
  },
];

export const RoutesComponent = () => {
  const { user } = useAuth();

  return (
    <Routes>
      {routes.map((route: RouteType, index: number) => (
        <Route key={index} path={route.path} element={route.element} />
      ))}
      <Route
        path="*"
        element={<Navigate to={user && userRedirectionMapping[user.role]} />}
      />
    </Routes>
  );
};
