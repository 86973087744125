import { Box } from '@mui/material';
import SignUpSteps from 'pages/SignUp/Steps';
import { signUpContainer } from 'pages/SignUp/styles';

export const SignUp = () => {
  return (
    <Box sx={signUpContainer}>
      <SignUpSteps />
    </Box>
  );
};
