import { useState } from 'react';
import { EmailConfirmation } from './EmailConfirmation';
import { ConfirmationCode } from './ConfirmationCode';
import { ResetPassword } from './ResetPassword';
import { PasswordChanged } from './PasswordChanged';
import { AuthLayout } from 'components/Layout';

type Steps = 'email' | 'confirmation' | 'reset' | 'success';

const ForgotPassword = () => {
  const [step, setStep] = useState<Steps>('email');
  const [email, setEmail] = useState<string>('');
  const [emailToken, setEmailToken] = useState<string>('');

  return (
    <AuthLayout>
      {step === 'email' && (
        <EmailConfirmation setStep={setStep} setEmail={setEmail} />
      )}
      {step === 'confirmation' && (
        <ConfirmationCode
          setStep={setStep}
          email={email}
          setEmailToken={setEmailToken}
        />
      )}
      {step === 'reset' && (
        <ResetPassword
          setStep={setStep}
          email={email}
          emailToken={emailToken}
        />
      )}
      {step === 'success' && <PasswordChanged setStep={setStep} />}
    </AuthLayout>
  );
};

export default ForgotPassword;
