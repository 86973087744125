import { Box } from '@mui/material';
import { ProtocolLendingTable } from 'components/Tables/ProtocolTables/ProtocolLendingTable';

export const Lending = (props: any) => {
  const { portfolioItem } = props;
  return (
    <Box>
      {portfolioItem.detail?.supplyTokenList?.length ? (
        <ProtocolLendingTable
          tableOption="supplied"
          elements={portfolioItem.detail.supplyTokenList}
        />
      ) : (
        <></>
      )}
      {portfolioItem.detail?.borrowTokenList?.length ? (
        <ProtocolLendingTable
          tableOption="borrowed"
          elements={portfolioItem.detail.borrowTokenList}
        />
      ) : (
        <></>
      )}
      {portfolioItem.detail?.rewardTokenList?.length ? (
        <ProtocolLendingTable
          tableOption="rewards"
          elements={portfolioItem.detail.rewardTokenList}
        />
      ) : (
        <></>
      )}
    </Box>
  );
};
