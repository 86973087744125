import { Box, Checkbox, FormHelperText, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';

export const CheckboxGroup = ({
  label,
  error,
  required,
  control,
  disabled,
  options,
  trigger,
}: any) => {
  const handleChange = (e: any, field: any) => {
    field.onChange(e.target.checked);
    options.map(({ name }: any) => trigger(name));
  };

  return (
    <Box
      width="100%"
      sx={{
        '& .react-datepicker-wrapper': {
          width: '100%',
        },
      }}
    >
      <Box display="flex" columnGap={0.5} marginY={0.5}>
        <Typography
          fontWeight="500"
          fontSize="16px"
          lineHeight="15px"
          color="#666666"
          marginTop="10px"
        >
          {label}
        </Typography>
        {required && (
          <Typography fontSize="15px" color="#ff4242">
            *
          </Typography>
        )}
      </Box>
      <Box>
        {options.map((option: any) => (
          <Box key={option.name} sx={{ display: 'flex', alignItems: 'center' }}>
            <Controller
              name={option.name}
              control={control}
              defaultValue={option.value || false}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  sx={{ color: error[option.name] ? '#d32f2f' : '' }}
                  checked={field.value}
                  inputProps={{ 'aria-label': 'controlled' }}
                  onChange={(e) => handleChange(e, field)}
                  disabled={disabled}
                />
              )}
            />
            <Typography
              fontWeight="500"
              fontSize="16px"
              lineHeight="15px"
              color="#666666"
            >
              {option.label}
            </Typography>
          </Box>
        ))}
      </Box>

      <FormHelperText sx={{ marginX: '14px' }}>
        {
          error[
            Object.keys(error).find((e) =>
              options.some(({ name }: any) => name === e),
            ) as any
          ]?.message
        }
      </FormHelperText>
    </Box>
  );
};
