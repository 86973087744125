import { Box } from '@mui/material';

export const columns = [
  {
    id: 'name',
    label: 'Opportunity Name',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'username',
    label: 'Supplier',
    minWidth: 100,
    align: 'left',
    render: (hosting: any) => {
      return <div>{hosting.username}</div>;
    },
  },
  {
    id: 'location',
    label: 'Location',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'installationCost',
    label: 'Installation cost, $',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'electricityCost',
    label: 'Electricity, $ (per watt)',
    minWidth: 100,
    align: 'left',
    render: (hosting: any) => {
      return <Box>{hosting.electricityCost.toFixed(4)}</Box>;
    },
  },
];
