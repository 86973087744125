import { Box } from '@mui/material';
import { ColumnsType } from 'components/Tables/BaseTable/types';
import moment from 'moment';

export const getColumns = (coinAbb: string): ColumnsType[] => [
  {
    id: 'date',
    label: 'Date',
    minWidth: 100,
    align: 'center',
    sortable: false,
    render: ({ timestamp }) => (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        {timestamp
          ? moment.unix(timestamp).format('YYYY-MM-DD')
          : 'Date Undefined'}
      </Box>
    ),
  },
  {
    id: 'transaction',
    label: `${coinAbb} Transaction`,
    minWidth: 100,
    align: 'center',
    sortable: false,
    render: ({ amount }) => (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        {amount.toLocaleString('en-US', {
          minimumFractionDigits: coinAbb === 'KAS' ? 3 : 6,
        })}{' '}
        {coinAbb}
      </Box>
    ),
  },
];
