export const outerContainer = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  rowGap: '27px',
};

export const innerContainer = {
  display: 'flex',
  columnGap: '20px',
  rowGap: '20px',
  width: '100%',
  flexWrap: 'wrap',
};

export const borderStyles = {
  borderTopLeftRadius: '0px',
  borderTopRightRadius: '0px',
  borderBottomLeftRadius: '10px',
  borderBottomRightRadius: '10px',
};

export const fieldsStyle = {
  display: 'flex',
  alignItems: 'center',
  columnGap: '80px',
  height: '60px',
  paddingLeft: {
    sm: '20px',
    xs: '20px',
    md: '30px',
    lg: '30px',
    xl: '30px',
  },
};

export const fieldsStyleGray = {
  display: 'flex',
  columnGap: '80px',
  height: '60px',
  alignItems: 'center',
  background: '#F7F8FA',
  paddingLeft: {
    sm: '20px',
    xs: '20px',
    md: '30px',
    lg: '30px',
    xl: '30px',
  },
};

export const contactDetailsBox = {
  padding: {
    sm: '20px',
    xs: '20px',
    md: '30px',
    lg: '30px',
    xl: '30px',
  },
  bgcolor: '#fff',
  flexGrow: '1',
  borderRadius: '20px',
  boxShadow: '0px 4px 20px 0px rgba(133, 133, 133, 0.24)',
};

export const contactDetailsInnerBox = {
  maxWidth: '1000',
  borderRadius: '10',
  border: '1px solid #E0E0E0',
};

export const emailMaxWidth = {
  sm: '80px',
  xs: '80px',
  md: '250px',
  lg: '250px',
  xl: '250px',
};

export const titleSize = {
  sm: '120px',
  xs: '130px',
  md: '250px',
  lg: '250px',
  xl: '250px',
};

export const titleFontSize = {
  sm: '12px',
  xs: '12px',
  md: '16px',
  lg: '16px',
  xl: '16px',
};

export const titleLabelGap = {
  sm: '30px',
  xs: '30px',
  md: '60px',
  lg: '80px',
  xl: '80px',
};
