import HttpService from './http.service';

class DashboardService {
  endpoint: string = 'dashboard';

  getDashboardHashrateChart = async (params: any) =>
    await HttpService.get(`${this.endpoint}/charts/hashrate`, params);

  getSupplierDashboardHashrateChart = async (params: any) =>
    await HttpService.get(`${this.endpoint}/charts/supplier/hashrate`, params);

  getDashboardFilterData = async (params: any) =>
    await HttpService.get(`${this.endpoint}/filtering-data`, params);

  getSupplierDashboardFilterData = async () =>
    await HttpService.get(`${this.endpoint}/supplier/filtering-data`);

  getDashboardStatistics = async (params: any) =>
    await HttpService.get(`${this.endpoint}/statistics`, params);

  getDashboardChartsData = async (params: any) =>
    await HttpService.get(`${this.endpoint}/charts`, params);

  getCurrencyStatistics = async (params: any) =>
    await HttpService.get(`${this.endpoint}/currency-statistics`, params);

  getContractlessCurrency = async (params: any) =>
    await HttpService.get(`${this.endpoint}/contractless-currency`, params);

  getDashboardPaymentAll = async (subAccountUserId: string, params: any) =>
    await HttpService.get(
      `${this.endpoint}/payment-all/${subAccountUserId}`,
      params,
    );

  getRoi = async (params: any) =>
    await HttpService.get(`${this.endpoint}/roi`, params);
}

export default new DashboardService();
