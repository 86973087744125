import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { pdfjs } from 'react-pdf';
import { Chart, registerables } from 'chart.js';
import HttpService from 'shared/services/http.service';
import { RoutesComponent } from 'routes';
import { ScrollToTop } from 'components/ScrollToTop';

import 'react-datepicker/dist/react-datepicker.css';

Chart.register(...registerables);

const url = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
pdfjs.GlobalWorkerOptions.workerSrc = url;

export const App = () => {
  const navigate = useNavigate();

  useEffect(() => {
    HttpService.setupAxiosInterceptors(navigate);
  }, []);

  return (
    <div className="App">
      <ScrollToTop />
      <RoutesComponent />
    </div>
  );
};
