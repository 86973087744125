import { Box, Skeleton, Typography } from '@mui/material';
import { formatBtc } from 'shared/utils/formatter';
import Elipse from 'assets/images/elipse.png';
import { InfoCard } from 'components/Cards/InfoCard';
import { colors } from 'shared/constants/theme';
import { backgroundStyles } from '../styles';

type Props = {
  selectedCurrency: any;
  btcSinceBeginning?: any;
};

export const TotalUpfront = (props: Props) => {
  const { selectedCurrency, btcSinceBeginning } = props;

  return (
    <InfoCard
      headerColor="white"
      infoIconColor="white"
      showDivider={false}
      showTime={false}
      fullHeight
      styles={backgroundStyles}
      content={
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
          }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: '20px',
                color: colors.white,
              }}
              variant="h5"
              fontWeight="500"
            >
              Total {selectedCurrency.coinAbb} earnings
            </Typography>
            <Typography
              sx={{
                fontSize: '32px',
                marginTop: '5px',
                fontWeight: '600',
                lineHeight: '56px',
                color: colors.white,
              }}
            >
              {typeof btcSinceBeginning === 'number' ? (
                <>
                  ${formatBtc(btcSinceBeginning)} {selectedCurrency.coinAbb}
                </>
              ) : (
                <Skeleton variant="rectangular" height={56} width={300} />
              )}
            </Typography>
          </Box>
        </Box>
      }
    />
  );
};
