export const BitmainLogo = () => (
  <svg
    width="105"
    height="17"
    viewBox="0 0 105 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 8.33066V3L4.84028 3.03632C9.14477 3.07265 9.72597 3.09989 10.1709 3.23611C11.3787 3.6266 11.8056 4.21688 11.8782 5.58814C11.9327 6.72329 11.7692 7.32265 11.2516 7.78579C11.0518 7.96741 10.8974 8.13087 10.9156 8.13996C10.9247 8.14904 11.0972 8.24893 11.2789 8.36698C11.851 8.71207 11.9872 9.1298 11.9872 10.61C11.9872 11.7906 11.969 11.9177 11.7602 12.3445C11.5059 12.844 11.1154 13.1709 10.4434 13.4343C10.0711 13.5796 9.56251 13.5978 5.0219 13.625L0 13.6522V8.33066ZM9.39904 11.8814C9.56251 11.8088 9.74413 11.6725 9.79862 11.5726C9.8531 11.4637 9.89851 10.9642 9.89851 10.4375C9.89851 9.54754 9.88943 9.48397 9.6624 9.2751L9.42629 9.05716L5.75748 9.02991L2.08868 9.00267V10.5101V12.0085H5.58494C8.40011 12.0085 9.14477 11.9813 9.39904 11.8814ZM9.19018 7.28632C9.7078 7.14102 9.8077 6.92308 9.8077 5.98771C9.8077 5.26122 9.78954 5.1704 9.58067 4.97062L9.36272 4.74359H5.72116H2.08868V6.06036V7.37713H5.47596C7.51924 7.37713 8.99039 7.34081 9.19018 7.28632Z"
      fill="#1D1D1D"
    />
    <path
      d="M14.2578 8.33203V3.01953H15.2567H16.2557V8.33203V13.6445H15.2567H14.2578V8.33203Z"
      fill="#1D1D1D"
    />
    <path
      d="M23.4292 9.19474V4.74496H20.7048H17.9805V3.88224V3.01953H24.4281H30.8758V3.88224V4.74496H28.1968H25.5179V9.19474V13.6445H24.4735H23.4292V9.19474Z"
      fill="#1D1D1D"
    />
    <path
      d="M32.5289 8.35859L32.5561 3.06425L33.4733 3.03701L34.3996 3.00977L37.5054 7.28701C39.2127 9.62995 40.6384 11.5552 40.6657 11.5461C40.702 11.5461 42.1096 9.62995 43.7987 7.27793L46.8772 3.01885H47.7308H48.5845V8.33134V13.6438H47.6309H46.6774L46.6593 10.0568L46.632 6.4697L44.0439 10.0477L41.4557 13.6348H40.5839L39.7121 13.6438L37.0877 10.0477L34.4632 6.44246L34.436 10.0477L34.4178 13.6438H33.4643H32.5107L32.5289 8.35859Z"
      fill="#1D1D1D"
    />
    <path
      d="M50.0104 13.3812C50.1012 13.2359 51.6905 10.8475 53.5339 8.06867L56.894 3.02861L57.5842 3.01953C58.129 3.01953 58.3016 3.05586 58.4105 3.18299C58.574 3.36462 65.2941 13.5446 65.2941 13.6082C65.2941 13.6264 64.7765 13.6445 64.1408 13.6354H62.9784L60.3358 9.48534C58.8828 7.20596 57.6568 5.33524 57.6205 5.33524C57.5932 5.33524 56.3491 7.20596 54.8689 9.48534L52.1718 13.6354L51.0094 13.6445H49.8379L50.0104 13.3812Z"
      fill="#1D1D1D"
    />
    <path
      d="M66.6553 8.33203V3.01953H67.6996H68.7439V8.33203V13.6445H67.6996H66.6553V8.33203Z"
      fill="#1D1D1D"
    />
    <path
      d="M71.0328 8.35859L71.0601 3.06425L71.8864 3.03701L72.7219 3.00977L76.6995 5.97932C78.8971 7.61393 81.2219 9.35752 81.8848 9.8479C82.5387 10.3383 83.1017 10.7379 83.129 10.7379C83.1562 10.7379 83.1925 9.01243 83.2016 6.9056L83.2289 3.06425L84.1188 3.03701L84.9997 3.00977V8.33134V13.6438H84.4821H83.9554L78.4158 9.59363L72.8763 5.5525L72.8491 9.59363L72.8309 13.6438H71.9228H71.0146L71.0328 8.35859Z"
      fill="#1D1D1D"
    />
  </svg>
);
