import { AffiliateWalletBalance } from 'pages/Affiliate/AffiliateOperation/Balances/WalletBalance';
import { TotalUpfront } from 'pages/Affiliate/AffiliateOperation/Balances/TotalUpfront';
import { Grid } from '@mui/material';

type Props = {
  totalUpfront?: number;
  totalSales?: number;
  walletData: {
    amountInCoin: number;
    walletAddress: string;
    amountInUsd: number;
  };
  message?: string;
  selectedCurrency: any;
  btcSinceBeginning?: any;
};

export const Balances = ({
  walletData,
  selectedCurrency,
  btcSinceBeginning,
}: Props) => {
  return (
    <Grid container rowSpacing={2} columnSpacing={2}>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <AffiliateWalletBalance
          wallet={walletData}
          selectedCurrency={selectedCurrency}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <TotalUpfront
          selectedCurrency={selectedCurrency}
          btcSinceBeginning={btcSinceBeginning}
        />
      </Grid>
    </Grid>
  );
};
