import HttpService from './http.service';

class VerificationService {
  private endpoint: string = 'verification';

  getVerificationData = async () =>
    await HttpService.get(`${this.endpoint}/info`);

  initVerification = async (data: any) =>
    await HttpService.post(`${this.endpoint}/init`, data);
}

export default new VerificationService();
