import { SupplierType } from './cells';

export const columns = [
  {
    id: 'email',
    label: 'Email',
    minWidth: 100,
    align: 'left',
  },

  {
    id: 'username',
    label: 'Username',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'role',
    label: 'Type',
    minWidth: 100,
    align: 'left',
    render: ({ role, isHardware }: any) => (
      <SupplierType type={role} isHardware={isHardware} />
    ),
  },
];
