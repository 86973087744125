import { ColumnsType } from 'components/Tables/BaseTable/types';

export const columns: ColumnsType[] = [
  {
    id: 'email',
    label: 'Email',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'companyName',
    label: 'Company',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'username',
    label: 'Username',
    minWidth: 100,
    align: 'left',
  },
];
