import { Box, Button, Typography } from '@mui/material';
import { useNotification } from 'shared/hooks/useNotification';
import countryCodes from 'shared/constants/country-codes.json';
import { Preview } from './SimulationTable';
import SimulationService from 'shared/services/simulation.service';
import { downloadFile } from 'shared/utils/fileDownloader';
import { RoiChartSection } from './RoiChart';
import {
  buttonsContainerStyles,
  cancelButtonStyles,
  submitButtonStyles,
} from './styles';
import { container, content, formTitle } from '../styles';
import { Currency } from 'shared/utils/enum/coinEnums';
import { useMemo } from 'react';
import { handleCurrencyName } from 'shared/utils/currency';
import { colors } from 'shared/constants/theme';

export const GeneratedSimulationForm = ({
  setIsLoading,
  setSimulationState,
  simulationData,
  setPdf,
  setSimulationId,
  fetchSimulations,
  clientName,
  setShowModal,
}: any): JSX.Element => {
  const { showSnackbar } = useNotification();
  const { data, hardwareOpportunity, hostingOpportunity } = simulationData;

  const onSubmit: any = async (): Promise<void> => {
    setIsLoading(true);
    try {
      const { pdf, id } = await SimulationService.createSimulation({
        data: { ...data, clientName },
        hostingOpportunity,
        hardwareOpportunity,
      });
      setPdf(pdf);
      setSimulationId(id);
      setSimulationState('done');
      await fetchSimulations();
    } catch (err: any) {
      showSnackbar(err.error, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const formatNumberToMoney = (number: number, rounded: boolean = false) => {
    if (number < 1000000 || rounded) {
      return '$' + Number(number?.toFixed(2)).toLocaleString('en-EN');
    } else if (number < 1000000000) {
      let millions = number / 1000000;
      millions = Math.round(millions * 100) / 100;
      let millionsString = Number(millions.toLocaleString('en-EN'))?.toFixed(2);
      return '$' + millionsString + 'M';
    } else {
      let billions = number / 1000000000;
      billions = Math.round(billions * 100) / 100;
      let billionsString = Number(billions.toLocaleString('en-EN'))?.toFixed(2);
      return '$' + billionsString + 'B';
    }
  };

  const revenueValue = useMemo(() => {
    if (
      data.currency === Currency.Bitcoin ||
      data.currency === Currency.BitcoinCash ||
      data.currency === Currency.Kaspa ||
      data.currency === Currency.Nervos
    ) {
      return `${data.bitcoinRevenue?.toFixed(3)} per TH/s`;
    }

    if (data.currency === Currency.Litecoin) {
      return `${data.bitcoinRevenue?.toFixed(4)} per MH/s`;
    }

    if (data.currency === Currency.Alephium) {
      return `${data.bitcoinRevenue?.toFixed(2)} per TH/s`;
    }

    return `${data.bitcoinRevenue?.toFixed(3)} per TH/s`;
  }, [data]);

  const decimalsCount = useMemo(() => {
    if (
      data.currency === Currency.Bitcoin ||
      data.currency === Currency.BitcoinCash ||
      data.currency === Currency.Kaspa ||
      data.currency === Currency.Nervos
    )
      return 3;

    if (data.currency === Currency.Litecoin) return 4;

    if (data.currency === Currency.Alephium) return 2;

    return 3;
  }, [data]);

  return (
    <Box sx={container}>
      <Box sx={content}>
        <Typography sx={formTitle}>
          {`Simulation preview for ${handleCurrencyName(data.currency)}`}
        </Typography>
        <Box width="100%">
          <Typography
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-start',
              fontWeight: '500',
              fontSize: '20px',
              marginBottom: 1,
              marginTop: 4,
            }}
          >
            Proposed opportunities
          </Typography>
          <Box
            sx={{
              borderRadius: '10px',
              border: '1px solid #EAEAEA',
              display: 'flex',
              width: '100%',
              padding: '15px',
              flexDirection: {
                xs: 'column',
                sm: 'row',
                md: 'row',
                lg: 'row',
              },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                width: '50%',
                alignItems: 'center',
                columnGap: 1,
              }}
            >
              <Typography
                sx={{
                  color: colors.lightGreen,
                  fontWeight: 500,
                  fontSize: '14px',
                  display: 'flex',
                }}
              >
                Hosting:
              </Typography>
              <Typography
                sx={{
                  color: colors.darkBlue,
                  fontWeight: 500,
                  fontSize: '14px',
                }}
              >
                {
                  countryCodes.countries.find((country) => {
                    return country.alpha2 === data.location;
                  })?.country
                }
              </Typography>
              {data.hostingPriority ? (
                <Box
                  sx={{
                    background: colors.lightGreen,
                    color: '#fff',
                    borderRadius: '6px',
                    padding: '5px',
                  }}
                >
                  Priority
                </Box>
              ) : (
                <></>
              )}
            </Box>
            <Box
              sx={{
                display: 'flex',
                width: {
                  xs: '100%',
                  sm: '50%',
                  md: '50%',
                  lg: '50%',
                },
                alignItems: 'center',
                columnGap: 1,
                marginTop: {
                  xs: 2,
                  sm: 0,
                  md: 0,
                  lg: 0,
                },
              }}
            >
              <Typography
                sx={{
                  color: colors.lightGreen,
                  fontWeight: 500,
                  fontSize: '14px',
                  display: 'flex',
                }}
              >
                Hardware:
              </Typography>
              <Typography
                sx={{
                  color: colors.darkBlue,
                  fontWeight: 500,
                  fontSize: '14px',
                }}
              >
                {data.device}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box width="100%" marginTop={3}>
          <Typography
            sx={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-start',
              fontWeight: '500',
              fontSize: '20px',
              marginBottom: 1,
            }}
          >
            Simulation preview
          </Typography>
          <Box
            sx={{
              borderRadius: '10px',
              border: '1px solid #EAEAEA',
              width: '100%',
              padding: '15px',
              height: '370px',
              overflow: 'auto',
            }}
          >
            <Preview
              sectionName="Assets"
              sectionData={[
                {
                  name: 'Total asset',
                  value: formatNumberToMoney(
                    simulationData.data.totalAsset,
                    true,
                  ),
                },
              ]}
            />
            <Preview
              sectionName="Annual projections"
              sectionData={[
                {
                  name: 'Revenue',
                  value: formatNumberToMoney(data.revenue, true),
                  asterik: true,
                },
                {
                  name: 'Total cost',
                  value: formatNumberToMoney(data.totalCost, true),
                },
                {
                  name: 'Benefits',
                  value: formatNumberToMoney(data.benefits, true),
                },
                {
                  name: 'ROI',
                  value: `${data.roi > 0 ? data.roi?.toFixed(2) : '0.00'}%`,
                },
              ]}
            />
            <Typography sx={{ fontSize: '11px', marginTop: '10px' }}>
              <sup>*</sup>
              Annual revenue calculated with daily revenue equal to $
              {revenueValue} (average for past 3 months)
            </Typography>
            <RoiChartSection data={data} />
            <Preview
              sectionName={`Key statistic for last ${data.currency === Currency.Bitcoin ? '36' : '12'} months`}
              sectionData={[
                {
                  name: 'Min',
                  value: `$${data.statisticsMin?.toFixed(decimalsCount)}`,
                },
                {
                  name: 'Average',
                  value: `$${data.statisticsAverage?.toFixed(decimalsCount)}`,
                },
                {
                  name: 'Max',
                  value: `$${data.statisticsMax?.toFixed(decimalsCount)}`,
                },
              ]}
            />
            <Preview
              sectionName="Hardware Setup"
              sectionData={[
                { name: 'Number of miners', value: data.minerNumbers },
                { name: 'Expect lifespan', value: `${data.lifespan} years` },
                {
                  name: 'Price machine installed',
                  value: formatNumberToMoney(data.priceMachineInstalled),
                },
                {
                  name: 'Machine electric power',
                  value: `${data.electricity} Watt`,
                },
              ]}
            />
            <Preview
              sectionName="Project Setup"
              sectionData={[
                {
                  name: 'Hearst upfront (on time)',
                  value: `${data.hearstUpfront?.toFixed(2)}%`,
                },
                { name: 'Pool cost', value: `${data.poolCost?.toFixed(2)}%` },
                {
                  name: 'Elec + Hosting cost',
                  value: `${data.electricityAndHostingCost?.toFixed(3)} $/KWh`,
                },
                {
                  name: 'Upfront hosting',
                  value: `${data.upfrontHosting} months`,
                },
                {
                  name: 'Hearst Management fees',
                  value: `${data.hearstManagementFees?.toFixed(2)}%`,
                },
              ]}
            />
            <Preview
              sectionName="Cost breakdown"
              sectionData={[
                {
                  name: 'Pool',
                  value: formatNumberToMoney(data.poolCostTotal, true),
                },
                {
                  name: 'Electricity',
                  value: formatNumberToMoney(
                    data.electricityAndHostingCostTotal,
                    true,
                  ),
                },
                {
                  name: 'Fees',
                  value: formatNumberToMoney(data.managmentFeeTotal, true),
                },
              ]}
            />
          </Box>
        </Box>
        <Box sx={buttonsContainerStyles}>
          {data.pdf ? (
            <Button
              sx={cancelButtonStyles}
              onClick={() => setShowModal(false)}
              type="button"
            >
              Close
            </Button>
          ) : (
            <Button
              sx={cancelButtonStyles}
              onClick={() => setSimulationState('empty')}
              type="button"
            >
              Back to editing
            </Button>
          )}
          {data.pdf ? (
            <Button
              sx={submitButtonStyles}
              onClick={() => {
                downloadFile(data.pdf);
                setShowModal(false);
              }}
            >
              Download PDF
            </Button>
          ) : (
            <Button sx={submitButtonStyles} type="submit" onClick={onSubmit}>
              Generate simulation PDF
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
};
