export function makeUrl(
  url: string,
  params: Record<string, string | undefined>,
) {
  let newUrl = url || '';

  Object.keys(params).forEach((key) => {
    newUrl = newUrl.replace(`:${key}`, params[key] || key);
  });

  return newUrl;
}
