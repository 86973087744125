import TableHead from '@mui/material/TableHead';
import { ColumnsType } from 'components/Tables/BaseTable/types';
import { EnhancedTableHead } from 'components/Tables/RedesignedTable/SortableTableHead';
import {
  StyledTableCell,
  StyledTableRow,
} from 'components/Tables/RedesignedTable/styles';
import { colors } from 'shared/constants/theme';

type Props = {
  show?: boolean;
  columns: ColumnsType[];
  order: 'asc' | 'desc';
  orderBy: any;
  setOrder: any;
  setOrderBy: any;
};
export const RedesignedTableHead = ({
  columns,
  order,
  orderBy,
  setOrderBy,
  setOrder,
  show,
}: Props) => {
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <TableHead
      className="tableHead"
      sx={{ backgroundColor: colors.lightGreen }}
    >
      <StyledTableRow>
        {show && (
          <StyledTableCell
            align="center"
            style={{
              minWidth: 100,
              fontSize: '14px',
              fontWeight: '600',
              color: colors.white,
              backgroundColor: colors.lightGreen,
            }}
          >
            Actions
          </StyledTableCell>
        )}
        <EnhancedTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          columns={columns}
        />
      </StyledTableRow>
    </TableHead>
  );
};
