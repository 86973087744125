import { useCallback, useEffect, useMemo, useState } from 'react';
import { BaseTable } from 'components/Tables/BaseTable';
import { columns } from './columns';
import { CustomModal } from 'components/Modals/CustomModal';
import { Box } from '@mui/material';
import { DeleteModal } from 'components/Modals/DeleteModal';
import { WarningModal } from 'components/Modals/WarningModal';
import UsersService from 'shared/services/users.service';
import HostingService from 'shared/services/hosting.service';
import { useNotification } from 'shared/hooks/useNotification';
import { AdminHostingForm } from 'components/Forms/AdminHostingForm';

export const HostingOpportunitiesTable = ({ hardwareChange }: any) => {
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [supplierAccounts, setSupplaerAccounts] = useState([]);
  const [hostingData, setHostingData] = useState<any[] | null>(null);
  const [hostingOpps, setHostingOpps] = useState<any[] | null>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [delHostingID, setDelHostingID] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const { showSnackbar } = useNotification();

  const fetchAccount = async (name = '') => {
    setHostingOpps(null);
    try {
      const response = await HostingService.getHostings({
        pageNumber: currentPage,
        limit: rowsPerPage,
        name,
      });

      const { data, totalPages } = response;

      setHostingOpps(data);
      setTotalPages(totalPages);
    } catch (err: any) {
      console.log(err);
    }
  };

  const fetchSuppliers = async () => {
    setSupplaerAccounts([]);
    try {
      const response = await UsersService.getAllSuppliers();

      const { users } = response;

      const hostingSuppliers = users.filter(
        (supplier: any) => supplier.role === 'Supplier',
      );

      setSupplaerAccounts(hostingSuppliers);
    } catch (err: any) {
      console.log(err);
    }
  };

  const handleEdit = (hosting: any): void => {
    setShowEditModal(true);
    setHostingData(hosting);
  };

  const handleCountItems = (event: number) => {
    setRowsPerPage(event);
    setCurrentPage(1);
  };

  useEffect(() => {
    fetchAccount();
  }, [currentPage, rowsPerPage, hardwareChange]);

  useEffect(() => {
    fetchSuppliers();
  }, []);

  const connectedHardware = useMemo(() => {
    const selectedHostingOpportunity = hostingOpps?.find(
      (opp) => opp.id === delHostingID,
    );

    return selectedHostingOpportunity
      ? selectedHostingOpportunity.hardware
      : [];
  }, [delHostingID, hostingOpps]);

  const handleDeleteHosting = useCallback(async () => {
    try {
      if (delHostingID) {
        await HostingService.deleteHosting(delHostingID);
        fetchAccount();
        setDelHostingID('');
        showSnackbar('Successfully Deleted', 'success');
      }
    } catch (err: any) {
      showSnackbar(err.error, 'error');
    } finally {
      setIsLoading(false);
    }
  }, [delHostingID]);

  return (
    <Box marginY="30px">
      <CustomModal
        open={showModal}
        onClose={() => setShowModal(false)}
        maxWidth="750px"
        backdropOpen={isLoading}
      >
        <AdminHostingForm
          setShowModal={setShowModal}
          onFinish={fetchAccount}
          suppliers={supplierAccounts}
          setIsLoading={setIsLoading}
        />
      </CustomModal>
      <BaseTable
        tableName="Hosting Opportunities"
        items={hostingOpps}
        columns={columns}
        setShowModal={setShowModal}
        handleEdit={handleEdit}
        show={true}
        setCurrentPage={setCurrentPage}
        onRowDelete={setDelHostingID}
        currentPage={currentPage}
        totalPages={totalPages}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={handleCountItems}
        fetchData={fetchAccount}
        searchable={true}
      />
      <CustomModal
        open={showEditModal}
        onClose={() => setShowEditModal(false)}
        maxWidth="750px"
        backdropOpen={isLoading}
      >
        <AdminHostingForm
          setShowModal={setShowEditModal}
          editData={hostingData}
          onFinish={fetchAccount}
          suppliers={supplierAccounts}
          setIsLoading={setIsLoading}
        />
      </CustomModal>
      <CustomModal
        open={!!delHostingID}
        onClose={() => setDelHostingID('')}
        backdropOpen={isLoading}
      >
        <Box className="container">
          {!connectedHardware.length ? (
            <DeleteModal
              title="Hosting opportunity"
              onClick={handleDeleteHosting}
              onCancel={() => setDelHostingID('')}
            />
          ) : (
            <WarningModal
              onCancel={() => setDelHostingID('')}
              devices={connectedHardware}
            />
          )}
        </Box>
      </CustomModal>
    </Box>
  );
};
