import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { ColumnsType } from 'components/Tables/BaseTable/types';
import { Link } from 'react-router-dom';
import { makeUrl } from 'routes/makeUrl';
import { SeeMoreButton } from '../BaseTable/SeeMoreButton';
import { routesMapping } from 'routes/mappings';
import { UserRole } from 'shared/utils/enum/UserEnum';

export const columns: ColumnsType[] = [
  {
    label: 'Details',
    sortable: false,
    minWidth: 150,
    align: 'left',
    render: ({ id: customerId, role }) =>
      role === UserRole.CUSTOMER ? (
        <SeeMoreButton
          route={makeUrl(routesMapping.adminUser, { customerId })}
        />
      ) : (
        <></>
      ),
  },
  {
    id: 'email',
    label: 'Email',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'companyName',
    label: 'Company',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'username',
    label: 'Username',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'view',
    label: 'View',
    minWidth: 100,
    align: 'left',
    sortable: false,
    render: ({ id, role }: any) =>
      role === 'Customer' ? (
        <Link
          to={{ pathname: `/admin/customer-view/${id}/home` }}
          target="_blank"
        >
          <VisibilityOutlinedIcon
            fontSize="small"
            sx={{
              color: '#323232',
              ':hover': {
                cursor: 'pointer',
              },
            }}
          />
        </Link>
      ) : (
        '-'
      ),
  },
];
