import { createContext, useState } from 'react';
import { Snackbar, Alert, AlertColor } from '@mui/material';
import {
  NotificationContextProviderProps,
  SnackbarContentTypes,
} from './types';

export const NotificationContext = createContext({});

export const NotificationProvider = (
  props: NotificationContextProviderProps,
) => {
  const { children } = props;
  const [snackbarContent, setSnackbarContent] = useState<SnackbarContentTypes>({
    message: null,
    severity: 'success',
  });
  const [open, setOpen] = useState(false);

  const showSnackbar = (message: string, severity: AlertColor) => {
    setSnackbarContent({
      message,
      severity,
    });
    setOpen(true);
  };
  const handleClose = (_: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') return;
    return setOpen(false);
  };

  return (
    <NotificationContext.Provider value={{ showSnackbar }}>
      {children}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={open}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        <Alert
          variant="filled"
          severity={snackbarContent.severity}
          sx={{ width: '100%', color: 'white', fontWeight: 400 }}
        >
          {snackbarContent?.message}
        </Alert>
      </Snackbar>
    </NotificationContext.Provider>
  );
};
