export const getSixPreviousMonths = () => {
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const today = new Date();
  const result = [];

  for (let i = 0; i <= 5; i++) {
    const previousMonth = new Date(today);
    previousMonth.setMonth(today.getMonth() - i);

    const monthName = months[previousMonth.getMonth()];
    result.push(`${monthName}-${previousMonth.getFullYear()}`);
  }

  return result.reverse();
};
