import { useMemo } from 'react';
import { Box } from '@mui/material';
import { RedesignedTable } from '../../RedesignedTable';
import { columns } from './columns';

export const ProtocolFarmingTable = (props: any) => {
  const { elements, rewards, usdFarmingValue } = props;

  const tableColumns = useMemo(() => {
    const rewardsValue = rewards || [];
    return columns(rewardsValue, usdFarmingValue);
  }, [rewards, usdFarmingValue]);

  return (
    <Box sx={{ marginTop: '20px' }}>
      <RedesignedTable
        items={elements}
        columns={tableColumns}
        paginated={false}
        wrapped={false}
      />
    </Box>
  );
};
