export const columns = [
  {
    id: 'id',
    label: 'ID',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'email',
    label: 'Email',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'username',
    label: 'Username',
    minWidth: 100,
    align: 'left',
  },
];
