export const itemHeaderStyles = {
  color: '#131613',
  fontSize: {
    xl: 20,
    lg: 20,
    md: 15,
    sm: 20,
    xs: 20,
  },
  fontWeight: '500',
};
