import { useState } from 'react';
import { Box } from '@mui/system';
import { CustomModal } from 'components/Modals/CustomModal';
import { DeleteModal } from 'components/Modals/DeleteModal';
import { BaseTable } from 'components/Tables/BaseTable';
import { columns } from './columns';
import { useNotification } from 'shared/hooks/useNotification';
import FeeService from 'shared/services/fee.service';
import { useParams } from 'react-router-dom';
import { FeeForm } from 'components/Forms/FeeForm';

export const AdminFeesTable = () => {
  const [fee, setFee] = useState<Record<string, any> | null>(null);
  const [delFee, setDelFee] = useState<boolean>(false);
  const [feeData, setFeeData] = useState<Record<string, any>>([]);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);
  const [isFeeAdded, setFeeAdded] = useState<boolean>(false);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const { id, customerId } = useParams();

  const { showSnackbar } = useNotification();

  const fetchFee = async () => {
    setFee(null);
    try {
      const data = await FeeService.getUserFee(id! || customerId, {
        pageNumber: currentPage,
        limit: rowsPerPage,
      });
      const { fee, totalPages } = data;
      setFee(fee);
      setTotalPages(totalPages);
    } catch (err) {
      console.log(err);
    }
  };

  const handleDeleteRoi = async (roiId: string) => {
    setDelFee(true);
    setFeeData({ roiId });
  };

  const removeROI = async () => {
    await FeeService.deleteFee(feeData.roiId);
    await fetchFee();
    setDelFee(false);
    showSnackbar('Successfully Deleted', 'success');
  };

  const handleEdit = (roi: any) => {
    setShowEditModal(true);
    setFeeData(roi);
  };

  const handleCountItems = (event: number) => {
    setRowsPerPage(event);
    setCurrentPage(1);
  };

  return (
    <>
      <BaseTable
        tableName="Fees"
        items={fee}
        columns={columns}
        setShowModal={setFeeAdded}
        fetchData={fetchFee}
        handleEdit={handleEdit}
        onRowDelete={(event) => handleDeleteRoi(event)}
        totalPages={totalPages}
        show={true}
        rowsPerPage={rowsPerPage}
        setCurrentPage={setCurrentPage}
        setRowsPerPage={handleCountItems}
        currentPage={currentPage}
        searchable={false}
      />
      <CustomModal open={showEditModal} onClose={() => setShowEditModal(false)}>
        <FeeForm
          setShowModal={setShowEditModal}
          editData={feeData}
          onFinish={fetchFee}
        />
      </CustomModal>
      <CustomModal open={!!delFee} onClose={() => setDelFee(false)}>
        <Box className="container">
          <DeleteModal
            title="ROI"
            onClick={removeROI}
            onCancel={() => setDelFee(false)}
          />
        </Box>
      </CustomModal>
      <CustomModal open={isFeeAdded} onClose={() => setFeeAdded(false)}>
        <FeeForm setShowModal={setFeeAdded} onFinish={fetchFee} />
      </CustomModal>
    </>
  );
};
