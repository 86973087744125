import { Layout } from 'components/Layout';
import { WalletStatus } from './WalletStatus';
import { EarnedBtc } from './EarnedBTC';
import { TransactionSection } from './TransactionSection';
import { Box } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import DashboardService from 'shared/services/dashboard.service';
import { Filter } from 'components/Filter';
import BitcoinService from 'shared/services/bitcoin.service';
import { useParams } from 'react-router-dom';
import { useNotification } from 'shared/hooks/useNotification';
import { handleCoinAbb } from 'shared/utils/currency';

const Transaction = ({ currency, currencyValue }: any) => {
  const [filterData, setFilterData] = useState<any>({
    contracts: [],
  });
  const [earnedBtcStatistics, setEarnedBtcStatistics] = useState<any>(null);
  const [earnedBtcChart, setEarnedBtcChart] = useState<any>(null);
  const [walletTransactions, setWalletTransactions] = useState<any>(null);
  const [walletData, setWalletData] = useState<any>(null);
  const { customerId } = useParams();

  const { showSnackbar } = useNotification();

  const getFilteredData: (contractIds?: string[]) => Promise<void> = async (
    contractIds,
  ) => {
    setEarnedBtcStatistics(null);
    setEarnedBtcChart(null);
    await getBtcStatistics(contractIds);
  };

  const getDashboardFilterData = async () => {
    try {
      const data = await DashboardService.getDashboardFilterData({
        customerId,
        currency: currencyValue,
      });
      return setFilterData(data);
    } catch (err: any) {}
  };

  const getBtcStatistics: (contractIds?: string[]) => Promise<void> = async (
    contractIds,
  ) => {
    try {
      const { chart, ...data } = await BitcoinService.getBitcoinStatistics({
        contractIds,
        customerId,
        currency: currencyValue,
      });

      setEarnedBtcChart(chart);
      return setEarnedBtcStatistics(data);
    } catch (err: any) {}
  };

  const getWalletData = async () => {
    try {
      const { transactions, ...data } = await BitcoinService.getWalletData({
        customerId,
        currency: currencyValue,
      });

      setWalletTransactions(transactions);
      return setWalletData(data);
    } catch (err: any) {
      return showSnackbar(err.error, 'error');
    }
  };
  useEffect(() => {
    getDashboardFilterData();
    getBtcStatistics();
    getWalletData();
  }, []);

  const subAccountUserIds = useMemo(
    () => filterData.contracts.map((contract: any) => contract.name).sort(),
    [filterData.contracts],
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        rowGap: '60px',
        paddingLeft: '20px',
      }}
    >
      <WalletStatus
        wallet={walletData}
        transactions={walletTransactions}
        currency={currency}
        coinAbb={handleCoinAbb(currency)}
      />
      <Filter
        marginTop={'0px'}
        contracts={subAccountUserIds}
        sectionName={`Earned ${currency} rewards`}
        onFilter={getFilteredData}
      />
      <EarnedBtc
        statistics={earnedBtcStatistics}
        chart={earnedBtcChart}
        coinAbb={handleCoinAbb(currency)}
        currency={currency}
        currencyValue={currencyValue}
      />
      <TransactionSection
        filterData={filterData}
        coinAbb={handleCoinAbb(currency)}
        currency={currency}
      />
    </Box>
  );
};

export default Transaction;
