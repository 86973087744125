import { envConfig } from 'config/env';
import HttpService from './http.service';

class FilesService {
  endpoint: string = 'files';

  uploadFile = async (file: any) =>
    await HttpService.post(`${this.endpoint}`, file);

  getFileUrl = (fileName: string) =>
    `${envConfig.API_BASE_URL}/${this.endpoint}/${fileName}`;

  deleteInvoice = async (fileId: string) =>
    await HttpService.delete(`${this.endpoint}/${fileId}`);
}

export default new FilesService();
