import { Box, Button, Typography } from '@mui/material';
import { buttonSection, okButton, warningModal } from './styles';
import DeleteIcon from 'assets/images/deleteIcon.svg';

interface WarningModalProps {
  onCancel: () => void;
  devices: string[];
}

export const WarningModal = ({
  onCancel,
  devices,
}: WarningModalProps): JSX.Element => {
  return (
    <Box sx={warningModal}>
      <img className="warningIcon" src={DeleteIcon} alt="logo" />
      <Typography>
        Please, remove association with the following hardware to delete this
        hosting opportunity
      </Typography>
      {devices.map((device: string, index: number) => (
        <p key={index}>{device}</p>
      ))}
      <Box sx={buttonSection}>
        <Button onClick={onCancel} sx={okButton}>
          Ok
        </Button>
      </Box>
    </Box>
  );
};
