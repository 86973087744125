import HttpService from './http.service';

class WalletService {
  private endpoint: string = 'wallet';

  getWalletData = async (params: any) =>
    await HttpService.get(`${this.endpoint}`, params);
}

export default new WalletService();
