import {
  Box,
  Checkbox,
  FormControl,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from '@mui/material';
import { SectionHeading } from 'components/SectionHeading';
import { useState } from 'react';

export const Filter = (props: any) => {
  const { onFilter, contracts, sectionName } = props;
  const [contractState, setContractState] = useState<any>([]);
  const [onOpenState, setOnOpenState] = useState<string>('');

  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setContractState(typeof value === 'string' ? value.split(',') : value);
  };

  const onClose = () => {
    if (onOpenState === JSON.stringify(contractState)) {
      setOnOpenState('');
    } else {
      onFilter(contractState);
    }
  };

  return (
    <Box
      marginTop={props.marginTop || 8}
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        columnGap: {
          xs: 5,
          sm: 5,
        },
      }}
    >
      <SectionHeading name={sectionName} />
      <FormControl sx={{ m: 1, width: 470 }} size="medium">
        <Select
          sx={{
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'white !important',
            },
            bgcolor: 'white',
            boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
          }}
          multiple
          input={<OutlinedInput label="Tag" />}
          renderValue={(selected: any) => {
            if (selected.length === 0) return 'All Contracts';
            return selected.join(', ');
          }}
          onOpen={() => {
            setOnOpenState(JSON.stringify(contractState));
          }}
          onClose={onClose}
          displayEmpty
          value={contractState}
          onChange={handleChange}
          label="All Contracts"
        >
          {contracts.map((contract: any) => (
            <MenuItem key={contract} value={contract} sx={{ width: '100%' }}>
              <Checkbox checked={contractState.indexOf(contract) > -1} />
              <ListItemText primary={contract} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};
