import { getColumns } from './columns';
import { RedesignedTable } from '../RedesignedTable';

export const TransactionsTable = (props: any) => {
  const { transactions, coinAbb } = props;

  return (
    <RedesignedTable
      items={transactions}
      columns={getColumns(coinAbb)}
      wrapped={false}
    />
  );
};
