import { Button } from '@mui/material';
import DeleteIcon from 'assets/images/deleteIcon.svg';
import { DeleteModalProps } from './types';

import './index.css';

export const DeleteModal = ({
  title,
  onClick,
  onCancel,
}: DeleteModalProps): JSX.Element => {
  return (
    <div className="deleteModal">
      <img className="deleteIcon" src={DeleteIcon} alt="logo" />
      <h1>Are you sure?</h1>
      <p>Do you really want to delete this {title}?</p>
      <p>This process can not be undone</p>
      <div className="buttonSection">
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={onClick}>Delete</Button>
      </div>
    </div>
  );
};
