import HttpService from './http.service';

class FeeService {
  endpoint: string = 'fees';

  getUserFee = async (id: any, params: any) =>
    await HttpService.get(`${this.endpoint}/user/${id}`, params);

  createFee = async (payload: any) =>
    await HttpService.post(`${this.endpoint}/`, payload);

  updateFee = async (feeId: string, payload: any) =>
    await HttpService.put(`${this.endpoint}/${feeId}`, payload);

  deleteFee = async (feeId: string) =>
    await HttpService.delete(`${this.endpoint}/${feeId}`);
}

export default new FeeService();
