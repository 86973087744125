import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, TableCell } from '@mui/material';
import { Progress } from 'components/Progress';
import { StyledTableRow } from './styles';
import { EnhancedTableHead } from './SortableTableHead';

export const SimpleTable = (props: any) => {
  const { items, columns } = props;

  return (
    <Box
      sx={{
        width: '100%',
        borderRadius: '0px',
        height: '100%',
      }}
    >
      <TableContainer sx={{ padding: '0px', margin: '0px' }}>
        <Table stickyHeader>
          <TableHead>
            <StyledTableRow>
              <EnhancedTableHead columns={columns} align="center" />
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {!items ? (
              <Progress className="center" size={24} />
            ) : !items?.length ? (
              <Box className="center">EMPTY CONTENT</Box>
            ) : (
              items?.length &&
              items?.map((row: Record<string, any>) => (
                <TableRow key={row.id}>
                  {columns.map((column: any) => {
                    const value = row[column.id];
                    return (
                      <TableCell key={column.id} align="center">
                        {column.render
                          ? column.render(row)
                          : value !== undefined || value !== null
                            ? value
                            : '-'}
                      </TableCell>
                    );
                  })}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
