import { Box, Button, Typography } from '@mui/material';
import { KycIcon } from 'components/Icons/KYC';
import { CustomModal } from 'components/Modals/CustomModal';
import { AccountTypeState } from 'pages/SignUp/types';
import { useAuth } from 'shared/hooks/useAuth';
import { styles } from './styles';
import { useNotification } from 'shared/hooks/useNotification';

type Props = {
  accessToken: string;
  accountType: AccountTypeState;
};
export const StartKyc = ({ accessToken, accountType }: Props) => {
  const { showSnackbar } = useNotification();
  const { signUp } = useAuth();

  const onClose = async () => {
    try {
      if (accountType) {
        localStorage.setItem('accountType', accountType);
        await signUp(accessToken);
      }
    } catch (e: any) {
      showSnackbar(e.error, 'error');
    }
  };

  return (
    <Box>
      <CustomModal open={true} onClose={onClose}>
        <Box sx={styles.container}>
          <Box sx={styles.iconContainer}>
            <KycIcon />
          </Box>
          <Typography sx={styles.title}>
            Congratulations! You account is successfully created!
          </Typography>
          <Typography sx={styles.subtitle}>
            At the next step we will redirect you to the KYC/KYB flow to
            complete your identity verification.
          </Typography>
          <Button sx={styles.button} onClick={onClose} variant="contained">
            Go To dashboard
          </Button>
        </Box>
      </CustomModal>
    </Box>
  );
};
