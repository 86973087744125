export const IceRiverLogo = () => (
  <svg
    width="90"
    height="10"
    viewBox="0 0 303 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_3275_8678)">
      <path
        d="M10.0685 31.3984H0.353271L5.93834 0H15.6066L10.0685 31.3984Z"
        fill="url(#paint0_linear_3275_8678)"
      />
      <path
        d="M57.471 23.3259L49.8209 31.3984H34.0513C24.2422 31.3984 17.7185 25.4848 17.7185 17.5531C17.7185 7.22773 27.5745 0 39.5894 0H61.6012L53.9041 8.1664H38.1345C32.3617 8.1664 27.4806 11.8741 27.4806 16.8491C27.4806 20.7445 30.719 23.3259 35.4593 23.3259H57.471Z"
        fill="url(#paint1_linear_3275_8678)"
      />
      <path
        d="M97.3645 23.3259L89.6674 31.3984H59.4424L65.0274 0H101.354L93.6099 8.1664H73.2877L72.6306 11.9211H97.4584L90.7 19.0549H71.3634L70.6125 23.3259H97.3645Z"
        fill="url(#paint2_linear_3275_8678)"
      />
      <path
        d="M143.779 31.3984H130.779L125.241 23.0912H110.316L108.861 31.3984H99.1458L101.962 15.3472H129.277C132.515 15.3472 135.331 13.7984 135.331 11.264C135.331 9.19893 133.548 8.1664 130.544 8.1664H103.229L111.02 0H131.201C137.772 0 145.563 2.01813 145.563 9.52747C145.563 15.2064 141.48 19.9936 135.425 21.1669C136.317 21.9648 137.303 23.0912 139.039 25.2971L143.779 31.3984Z"
        fill="black"
      />
      <path
        d="M157.109 31.3984H147.393L152.978 0H162.647L157.109 31.3984Z"
        fill="black"
      />
      <path
        d="M178.182 0L188.648 20.8384L207.046 0H218.544L193.388 28.3947C191.745 30.2251 189.539 31.9147 186.489 31.9147C183.438 31.9147 181.936 30.3659 180.904 28.3947L166.307 0H178.182Z"
        fill="black"
      />
      <path
        d="M254.354 23.3259L246.657 31.3984H216.432L222.017 0H258.344L250.6 8.1664H230.277L229.62 11.9211H254.448L247.69 19.0549H228.353L227.602 23.3259H254.354Z"
        fill="black"
      />
      <path
        d="M300.771 31.3984H287.771L282.233 23.0912H267.308L265.853 31.3984H256.138L258.954 15.3472H286.269C289.507 15.3472 292.323 13.7984 292.323 11.264C292.323 9.19893 290.54 8.1664 287.536 8.1664H260.221L268.012 0H288.193C294.764 0 302.555 2.01813 302.555 9.52747C302.555 15.2064 298.472 19.9936 292.417 21.1669C293.309 21.9648 294.294 23.0912 296.031 25.2971L300.771 31.3984Z"
        fill="black"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_3275_8678"
        x1="7.97994"
        y1="0"
        x2="7.97994"
        y2="31.3984"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#169AAE" />
        <stop offset="1" stop-color="#146EA9" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_3275_8678"
        x1="39.6598"
        y1="0"
        x2="39.6598"
        y2="31.3984"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#169AAE" />
        <stop offset="1" stop-color="#146EA9" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_3275_8678"
        x1="80.3981"
        y1="0"
        x2="80.3981"
        y2="31.3984"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#169AAE" />
        <stop offset="1" stop-color="#146EA9" />
      </linearGradient>
      <clipPath id="clip0_3275_8678">
        <rect
          width="302.293"
          height="32"
          fill="white"
          transform="translate(0.353271)"
        />
      </clipPath>
    </defs>
  </svg>
);
