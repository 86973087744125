import HttpService from './http.service';

class ContractsService {
  endpoint: string = 'contracts';

  getContracts = async (params: any) =>
    await HttpService.get(`${this.endpoint}`, params);

  getContractsByUser = async (userId: string, params?: any) =>
    await HttpService.get(`${this.endpoint}/user/${userId}`, params);

  getContract = async (contractId: string) =>
    await HttpService.get(`${this.endpoint}/${contractId}`);

  getAdminDashboardStatistics = async () =>
    await HttpService.get(`${this.endpoint}/admin/statistics`);

  getAffiliateUpfront = async (params?: any) =>
    await HttpService.get(`${this.endpoint}/affiliate/upfront`, params);

  getAffiliateStatistics = async (params?: any) =>
    await HttpService.get(`${this.endpoint}/affiliate/statistics`, params);

  getAffiliateCustomerUpfront = async (customerId: string) =>
    await HttpService.get(`${this.endpoint}/affiliate/upfront/${customerId}`);

  getCompanyProfit = async (params: any) =>
    await HttpService.get(`${this.endpoint}/company/profit`, params);

  createContract = async (payload: any) =>
    await HttpService.post(`${this.endpoint}`, payload);

  updateContract = async (contractId: string, payload: any) =>
    await HttpService.put(`${this.endpoint}/${contractId}`, payload);

  deleteContract = async (contractId: string) =>
    await HttpService.delete(`${this.endpoint}/${contractId}`);
}

export default new ContractsService();
