import { envConfig } from 'config/env';
import { accessToken } from 'shared/constants/localStorage';
import FilesService from 'shared/services/files.service';

export const downloadFile = async (fileName: string) => {
  try {
    const fileUrl = FilesService.getFileUrl(fileName);

    // Using fetch instead of axios because of blob type issue present in axios.
    const response = await fetch(fileUrl);
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);

    a.click();
    window.URL.revokeObjectURL(url);
  } catch (err: any) {
    console.log(`Error downloading file: ${fileName}: ${err}`);
  }
};

export const downloadElectricityInvoicing = async (
  startDate: any,
  endDate: any,
) => {
  const apiUrl = `${envConfig.API_BASE_URL}/contracts/electricity-invoice?startDate=${startDate}&endDate=${endDate}`;
  try {
    const token = localStorage.getItem(accessToken);
    const response = await fetch(apiUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const [, fileName] = response.headers
      .get('Content-Disposition')!
      .split('filename=');

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    console.log(fileName);

    a.setAttribute('download', fileName);
    document.body.appendChild(a);

    a.click();
    window.URL.revokeObjectURL(url);
  } catch (err: any) {
    console.log(`Error downloading file: ${err}`);
  }
};
