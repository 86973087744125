import { Box } from '@mui/material';

export const SupplierType = ({ type, isHardware }: any) => {
  return (
    <Box sx={{ display: 'flex' }}>
      {type === 'Supplier' ? (
        <Box
          sx={{
            backgroundColor: '#74e6c4',
            borderRadius: '20px',
            padding: '2px 15px',
            marginRight: '10px',
          }}
        >
          Host
        </Box>
      ) : null}
      {type === 'HardwareSupplier' || isHardware ? (
        <Box
          sx={{
            backgroundColor: '#7398e6',
            borderRadius: '20px',
            padding: '2px 15px',
            marginRight: '10px',
          }}
        >
          Hard
        </Box>
      ) : null}
    </Box>
  );
};
