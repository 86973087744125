import { useMemo } from 'react';
import { Grid } from '@mui/material';
import { StatisticsCard } from 'components/Cards/StatisticsCard';
import { threeRowGridItem } from 'pages/Customer/Operation/styles';

export const Operations = ({ statistics }: any) => {
  const statisticsData = useMemo(
    () => [
      {
        title: 'Total Machines',
        value: statistics ? statistics.machines : null,
        currency: '',
      },
      {
        title: 'Total Hashrate',
        value: statistics ? statistics.hashRate.toFixed(2) : null,
        currency: 'PH/s',
      },
      {
        title: 'Total Assets',
        value: statistics
          ? `$${statistics.totalInvestment.toLocaleString('en-EN', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`
          : null,
        currency: '',
      },
    ],
    [statistics],
  );

  return (
    <>
      <Grid
        marginTop={0.5}
        marginBottom={5}
        container
        rowSpacing={2}
        columnSpacing={2}
      >
        {statisticsData.map((statistic: any) => (
          <Grid {...threeRowGridItem}>
            <StatisticsCard {...statistic} small colored />
          </Grid>
        ))}
      </Grid>
    </>
  );
};
