import { Security } from '@mui/icons-material';
import { LogoutIcon } from 'components/Icons/Logout';
import { ProfileIcon } from 'components/Icons/Profle';
import { SubaccountIcon } from 'components/Icons/Subaccount';

export const homeHeaderSettings = [
  {
    name: 'Profile',
    disabled: false,
    icon: ProfileIcon,
  },
  {
    name: 'Log Out',
    icon: LogoutIcon,
    disabled: false,
  },
];

export const customerSetting = (exclude: boolean) => [
  {
    name: 'Profile',
    disabled: exclude,
    icon: ProfileIcon,
  },
  {
    name: 'Sub Account',
    icon: SubaccountIcon,
    disabled: exclude,
  },
  {
    name: 'Verification',
    icon: Security,
    disabled: false,
  },
  {
    name: 'Log Out',
    icon: LogoutIcon,
    disabled: false,
  },
];

export const testSetting = (isVerified: boolean, isNewUser: boolean) => {
  if (!isNewUser && !isVerified) {
    return [
      {
        name: 'Profile',
        disabled: isNewUser && !isVerified,
        icon: ProfileIcon,
      },
      {
        name: 'Sub Account',
        icon: SubaccountIcon,
        disabled: isNewUser && !isVerified,
      },
      {
        name: 'Verification',
        icon: Security,
        disabled: false,
      },
      {
        name: 'Log Out',
        icon: LogoutIcon,
        disabled: false,
      },
    ];
  }

  return [
    {
      name: 'Profile',
      disabled: isNewUser && !isVerified,
      icon: ProfileIcon,
    },
    {
      name: 'Sub Account',
      icon: SubaccountIcon,
      disabled: isNewUser && !isVerified,
    },
    {
      name: 'Log Out',
      icon: LogoutIcon,
      disabled: false,
    },
  ];
};
