import { Box, Grid, Skeleton, Typography } from '@mui/material';
import { InfoCard } from 'components/Cards/InfoCard';
import { defaultLineChartStyles } from 'components/Charts/styles';
import { SectionHeading } from 'components/SectionHeading';
import { envConfig } from 'config/env';
import moment from 'moment';
import { useMemo } from 'react';
import { Bar } from 'react-chartjs-2';
import { colors } from 'shared/constants/theme';

const RoiStatistic = (props: any) => {
  const { color, title } = props;

  const sx = {
    fontSize: {
      xl: 50,
      lg: 40,
      md: 25,
      sm: 40,
      xs: 28,
    },
  };

  const titleSx = {
    fontSize: {
      xl: 20,
      lg: 18,
      md: 12,
      sm: 25,
      xs: 15,
    },
  };

  const dateSx = {
    fontSize: {
      xl: 15,
      lg: 15,
      md: 12,
      sm: 12,
      xs: 10,
    },
  };

  return (
    <InfoCard
      styles={{
        height: { xl: '316px', md: '250px' },
      }}
      showDivider={false}
      showTime={false}
      info={title}
      subtitle={title}
      content={
        <>
          <Grid container>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Typography sx={titleSx} fontWeight={500}>
                Last Month
              </Typography>
              <Typography sx={dateSx} color="#828282">
                {moment().subtract('1', 'months').format('MMMM yyyy')}
              </Typography>

              <Typography fontWeight={500} sx={sx} color={color}>
                -
              </Typography>
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
              <Typography sx={titleSx} fontWeight={500}>
                Since Beginning
              </Typography>
              <Typography sx={dateSx} color="#828282">
                {moment().subtract('1', 'months').format('MMMM yyyy')}
              </Typography>
              <Typography fontWeight={500} sx={sx} color={color}>
                -
              </Typography>
            </Grid>
          </Grid>
        </>
      }
    />
  );
};

const YearlyStatistic = (props: any) => {
  const { title } = props;

  const sx = {
    color: '#ffffff',
    fontSize: {
      xl: 50,
      lg: 40,
      md: 25,
      sm: 40,
      xs: 28,
    },
  };

  return (
    <InfoCard
      showDivider={false}
      showTime={false}
      info={title}
      subtitle={title}
      headerColor="#ffffff"
      infoIconColor="#ffffff"
      styles={{
        height: { md: '50%', lg: '50%', xl: '50%' },
        background: colors.lightGreen,
        border: 'unset',
      }}
      content={
        <Typography fontWeight={500} sx={sx}>
          -
        </Typography>
      }
    />
  );
};

export const UnavailablePerformance = () => {
  const projectedStatistics = [
    {
      title: 'Projected Yearly with collateral',
    },
    {
      title: 'Projected Yearly without collateral',
    },
  ];

  const calculatedStatistics = [
    {
      color: colors.darkGreen,
      title: (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            columnGap: 1,
          }}
        >
          Performance With Collateral
          <Box
            sx={{
              height: '15px',
              width: '15px',
              bgcolor: colors.darkGreen,
              borderRadius: '50%',
            }}
          ></Box>
        </Box>
      ),
    },
    {
      color: 'secondary.main',
      title: (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            columnGap: 1,
          }}
        >
          Performance Without Collateral
          <Box
            sx={{
              height: '15px',
              width: '15px',
              backgroundColor: 'secondary.main',
              borderRadius: '50%',
            }}
          ></Box>
        </Box>
      ),
    },
  ];

  return (
    <>
      <SectionHeading
        sx={{
          marginTop: 8,
        }}
        name="Your performance"
      />
      <Grid marginTop={1} container rowSpacing={2} columnSpacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <InfoCard
            styles={{
              height: { xl: '100%', lg: '100%', md: '100%' },
            }}
            subtitle="Perfomance Per Month"
            linkText="How it's calculated?"
            link={`${envConfig.API_BASE_URL}/files/performance_calculation.pdf`}
            title=""
            fullHeight
            content={
              <Box
                sx={{
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography>Data is temporarily unavailable</Typography>
              </Box>
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Box
            display="flex"
            sx={{
              flexDirection: {
                xs: 'column',
                sm: 'column',
                md: 'row',
                lg: 'row',
                xl: 'row',
              },
            }}
            columnGap={2.2}
            rowGap={2.2}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={7}
              lg={7}
              xl={7}
              display="flex"
              flexDirection="column"
              rowGap={2.2}
            >
              {calculatedStatistics.map((statistic) => (
                <RoiStatistic {...statistic} />
              ))}
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={5}
              lg={5}
              xl={5}
              display="flex"
              flexDirection="column"
              rowGap={2.2}
            >
              {projectedStatistics.map((statistic) => (
                <YearlyStatistic {...statistic} />
              ))}
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
