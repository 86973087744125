export const LogoutIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.0002 4.77507V19.2238C12.0003 19.7635 11.7555 20.274 11.3345 20.6117C10.9136 20.9495 10.362 21.0778 9.83526 20.9605L5.38941 19.9721C4.57575 19.7915 3.99683 19.0699 3.99683 18.2364V5.76348C3.99683 4.92969 4.57632 4.20794 5.39041 4.02776L9.83626 3.03935C10.3628 2.92228 10.9139 3.05054 11.3347 3.38802C11.7554 3.7255 12.0002 4.23571 12.0002 4.77507Z"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M8.99899 13.0204V11.0195"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.0035 7.99827V5.95542C20.0037 5.42472 19.7929 4.91572 19.4177 4.54046C19.0424 4.1652 18.5334 3.95444 18.0027 3.95459H15.0015"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20.0035 16.0017V18.0025C20.0037 18.5332 19.7929 19.0422 19.4177 19.4175C19.0424 19.7928 18.5334 20.0035 18.0027 20.0034H15.0015"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15.0015 12H20.0035"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.0027 14.0008L20.0035 12L18.0027 9.99915"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
