import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import moment from 'moment';
import { Box, Button, Typography } from '@mui/material';
import MachinesService from 'shared/services/machines.service';
import { useNotification } from 'shared/hooks/useNotification';
import { ControlledTextField } from 'components/ControlledInputs/TextField';
import { ControlledDatePicker } from 'components/ControlledInputs/DatePicker';
import { ControlledSelect } from 'components/ControlledInputs/Select';

const schema = yup.object().shape({
  id: yup.string(),
  serialNumber: yup.string().required('Serial Number is a required field'),
  status: yup.string().required('Status is a required field'),
  plugDate: yup.string().required('Plug Date is a required field'),
});

export const MachineForm = ({
  setShowModal,
  editData,
  setMachineAdded,
  onFinish,
}: any): JSX.Element => {
  const { id } = useParams();

  const { showSnackbar } = useNotification();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (machine: any) => {
    const payload: any = {
      machine,
      contractId: id,
    };

    try {
      if (editData?.id) {
        delete payload?.id;
        await MachinesService.updateMachine(editData.id, payload);
        showSnackbar('Successfully Edited', 'success');
      } else {
        await MachinesService.createMachine(payload);
        showSnackbar('Successfully Created', 'success');
      }
      onFinish();
      if (setMachineAdded) setMachineAdded(true);
    } catch (err: any) {
      showSnackbar(err.error, 'error');
    } finally {
      setShowModal(false);
    }
  };

  const statusOptions = [
    {
      value: 'Active',
      name: 'Active',
    },
    {
      value: 'Unplugged',
      name: 'Unplugged',
    },
    {
      value: 'Under_Maintenance',
      name: 'Under Maintenance',
    },
  ];

  return (
    <>
      <Box
        sx={{
          padding: 5,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <Typography variant="h5" fontWeight="500">
          {editData?.id ? 'Edit' : 'Add'} Machine
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
          {editData?.id && (
            <ControlledTextField
              disabled
              control={control}
              defaultValue={editData?.id ?? ''}
              name="id"
              label="ID"
              error={errors}
            />
          )}
          <ControlledTextField
            required
            control={control}
            defaultValue={editData?.serialNumber ?? ''}
            name="serialNumber"
            label="Serial Number"
            error={errors}
            placeholder="Serial Number"
          />
          <ControlledSelect
            required
            control={control}
            name="status"
            label="Status"
            defaultValue={editData?.status ?? ''}
            error={errors}
            options={statusOptions}
          />
          <ControlledDatePicker
            label="Plug Date"
            required
            name="plugDate"
            control={control}
            defaultValue={
              editData?.plugDate
                ? moment(editData?.plugDate).format('MM/DD/yyyy')
                : ''
            }
            error={errors}
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: 2,
            }}
          >
            <Button className="submitButton" type="submit">
              {!editData?.id ? 'Add Machine' : 'Edit Machine'}
            </Button>
          </Box>
        </form>
      </Box>
    </>
  );
};
