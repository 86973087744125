import { useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Progress } from 'components/Progress';
import { useNotification } from 'shared/hooks/useNotification';
import * as yup from 'yup';
import { ControlledTextField } from 'components/ControlledInputs/TextField';
import AuthService from 'shared/services/auth.service';
import { SvgComponentSee } from 'components/Icons/See';
import { SvgComponentHide } from 'components/Icons/Hide';
import { useNavigate } from 'react-router-dom';
import { LockIcon } from 'components/Icons/Lock';
import { authButtonStyles } from 'shared/styles/button';

const schema = yup.object().shape({
  password: yup
    .string()
    .min(6, 'Password must be at least 6 characters')
    .required('Password is a required field'),
  confirmPassword: yup
    .string()
    .required('Confirm password is a required field')
    .min(6)
    .oneOf([yup.ref('password')], 'Passwords do not match'),
});

export const ResetPassword = (props: any) => {
  const { setStep, email, emailToken } = props;

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const { showSnackbar } = useNotification();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const onSubmit = async (payload: any) => {
    setIsLoading(true);
    const { password } = payload;
    try {
      await AuthService.resetPassword({ password, email, emailToken });
      showSnackbar('Password resetted successfully', 'success');
      setStep('success');
    } catch (err: any) {
      showSnackbar(err.error, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form className="contentLogin" onSubmit={handleSubmit(onSubmit)}>
      <Box>
        <Box sx={{ display: 'flex', width: '80%', marginBottom: '15px' }}>
          <Typography
            color=""
            variant="h2"
            sx={{
              color: '#fff',
              fontSize: '48px',
              fontWeight: '600',
              textAlign: 'start',
            }}
          >
            Enter your new password
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', width: '100%', marginBottom: '30px' }}>
          <Typography
            variant="h3"
            sx={{
              color: 'white',
              fontSize: '12px',
              display: 'flex',
              columnGap: '4px',
            }}
          >
            Choose a new password for your account
          </Typography>
        </Box>

        <Box className="dataContainer">
          <Box
            className="dataBox"
            sx={{ display: 'flex', flexDirection: 'column', rowGap: '30px' }}
          >
            <ControlledTextField
              control={control}
              defaultValue={''}
              name="password"
              error={errors}
              placeholder="Password"
              type={showPassword ? 'text' : 'password'}
              dark
              textFieldProps={{
                InputProps: {
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickShowPassword}>
                        {showPassword ? (
                          <SvgComponentSee />
                        ) : (
                          <SvgComponentHide />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                },
              }}
            />
            <ControlledTextField
              control={control}
              defaultValue={''}
              name="confirmPassword"
              error={errors}
              placeholder="Confirm Password"
              type={showConfirmPassword ? 'text' : 'password'}
              dark
              textFieldProps={{
                InputProps: {
                  startAdornment: (
                    <InputAdornment position="start">
                      <LockIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleClickShowConfirmPassword}>
                        {showConfirmPassword ? (
                          <SvgComponentSee />
                        ) : (
                          <SvgComponentHide />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                },
              }}
            />
          </Box>
        </Box>
      </Box>
      <Button
        sx={authButtonStyles}
        variant="contained"
        color="secondary"
        type="submit"
        fullWidth
      >
        {!isLoading ? 'Confirm' : <Progress color="inherit" size={20} />}
      </Button>
    </form>
  );
};
