import { Box } from '@mui/material';
import { SectionHeading } from 'components/SectionHeading';
import { TransactionTable } from 'components/Transactions';

export const TransactionSection = (props: any) => {
  const { filterData, currency, coinAbb } = props;

  return (
    <>
      <Box>
        <SectionHeading
          sx={{
            marginBottom: '30px',
          }}
          name="Transaction"
        />
        <TransactionTable
          contracts={filterData.contracts}
          currency={currency}
          coinAbb={coinAbb}
        />
      </Box>
    </>
  );
};
