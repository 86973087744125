import { Typography } from '@mui/material';

export const SectionHeading = (props: any) => {
  const { name, sx } = props;

  return (
    <Typography
      sx={{
        fontSize: {
          xl: 28,
          lg: 28,
          md: 28,
          sm: 20,
          xs: 20,
        },
        ...sx,
      }}
      fontWeight={600}
    >
      {name}
    </Typography>
  );
};
