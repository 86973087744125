export const columns = [
  {
    id: 'email',
    label: 'Email',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'owner',
    sortable: false,
    label: 'Owner',
    minWidth: 100,
    align: 'left',
    render: ({ owner: { email } }: any) => email,
  },
  {
    id: 'companyName',
    label: 'Company',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'username',
    label: 'Username',
    minWidth: 100,
    align: 'left',
  },
];
