import { useEffect, useState } from 'react';
import { Layout } from 'components/Layout';
import contractsService from 'shared/services/contracts.service';
import { useNotification } from 'shared/hooks/useNotification';
import { AffiliateCustomerOperations } from './Operations';
import { Customers } from '../AffiliateOperation/Customers';

export const AffiliateCustomers = () => {
  const [statistics, setStatistics] = useState<any>();
  const { showSnackbar } = useNotification();

  const getTotalUpfront = async () => {
    try {
      const affiliateStatistics =
        await contractsService.getAffiliateStatistics();

      setStatistics(affiliateStatistics);
    } catch (err: any) {
      showSnackbar(err.error, 'error');
    }
  };

  useEffect(() => {
    getTotalUpfront();
  }, []);

  return (
    <Layout
      backgroundHeight={{
        xs: '480px',
        sm: '330px',
        md: '330px',
        lg: '330px',
      }}
    >
      <AffiliateCustomerOperations
        totalSales={statistics?.totalSales}
        upfrontTotal={statistics?.upfrontTotal}
      />
      <Customers />
    </Layout>
  );
};
