import HttpService from './http.service';
class HardwareService {
  endpoint: string = 'hardware';

  getHardwares = async (params: any) =>
    await HttpService.get(`${this.endpoint}`, params);

  createHardware = async (payload: any) =>
    await HttpService.post(`${this.endpoint}`, payload);

  updateHardware = async (hostingId: string, payload: any) =>
    await HttpService.put(`${this.endpoint}/${hostingId}`, payload);

  deleteHardware = async (hostingId: string) =>
    await HttpService.delete(`${this.endpoint}/${hostingId}`);
}

export default new HardwareService();
