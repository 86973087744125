import { Box } from '@mui/material';
import { Synaps } from '@synaps-io/verify-sdk';
import { Layout } from 'components/Layout';
import { useEffect, useState } from 'react';
import { useNotification } from 'shared/hooks/useNotification';
import VerificationService from 'shared/services/verification.service';

export const Verification = () => {
  const { showSnackbar } = useNotification();
  const [status, setStatus] = useState('');

  const initKyc = async () => {
    try {
      const { sessionId, type, status } =
        await VerificationService.initVerification({
          type: 'INDIVIDUAL',
        });

      if (status !== 'NOT_INITIATED') {
        await new Promise((r) => setTimeout(r, 2000));
        return Synaps.init({
          sessionId,
          mode: 'embed',
          service: type.toLowerCase(),
          lang: 'en',
        });
      }
    } catch (err: any) {
      showSnackbar(err.error, 'error');
    }
  };

  const getKycData = async () => {
    try {
      const { sessionId, status, type } =
        await VerificationService.getVerificationData();

      setStatus(status);

      if (status === 'NOT_INITIATED') return;

      return Synaps.init({
        sessionId,
        mode: 'embed',
        service: type.toLowerCase(),
        lang: 'en',
      });
    } catch (err: any) {
      showSnackbar(err.error, 'error');
    }
  };

  useEffect(() => {
    getKycData();
  }, []);

  useEffect(() => {
    if (status !== 'NOT_INITIATED') return;
    initKyc();
  }, [status]);

  return (
    <Layout>
      <Box display="flex" justifyContent="center">
        <Box sx={{ width: 500, height: 900 }} id="synaps-wrapper" />
      </Box>
    </Layout>
  );
};
