import { useCallback, useEffect, useState } from 'react';
import { useNotification } from 'shared/hooks/useNotification';

export function useFetch<T>(
  fetchFunction: () => Promise<T> | undefined,
  deps: any[] = [],
): [T, (...args: any[]) => Promise<void>] {
  const [data, setData] = useState<T>({} as T);
  const { showSnackbar } = useNotification();

  const fetchData = useCallback(async () => {
    try {
      const fetchedData = await fetchFunction();
      fetchedData && setData(fetchedData);
    } catch (err: any) {
      showSnackbar(err.error, 'error');
    }
  }, [fetchFunction]);

  useEffect(() => {
    fetchData();
  }, deps);

  return [data, fetchData];
}
