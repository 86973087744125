import { Box } from '@mui/material';
import { Layout } from 'components/Layout';
import { ProfileLeftSideMenu } from 'components/Profile/ProfileLeftSideMenu';
import { ProfileDetailsForm } from 'components/Profile/ProfileDetailsForm';
import { ChangeProfilePasswordForm } from 'components/Profile/ProfilePasswordChangeForm';
import { Welcome } from 'pages/Customer/Operation/Welcome';
import { useMemo, useState } from 'react';

export const Profile = () => {
  const [selectedMenu, setSelectedMenu] = useState('profile');

  const ProfileContent = useMemo(() => {
    if (selectedMenu === 'profile') {
      return <ProfileDetailsForm />;
    }

    if (selectedMenu === 'security') {
      return <ChangeProfilePasswordForm />;
    }
  }, [selectedMenu]);

  return (
    <Layout fullBackground={true}>
      <Welcome />
      <Box
        sx={{
          display: {
            xs: 'block',
            sm: 'block',
            md: 'flex',
            lg: 'flex',
            xl: 'flex',
          },
          columnGap: '20px',
        }}
      >
        <ProfileLeftSideMenu
          selectedMenu={selectedMenu}
          setSelectedMenu={setSelectedMenu}
        />
        {ProfileContent}
      </Box>
    </Layout>
  );
};
