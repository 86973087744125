import HttpService from './http.service';

class ChainService {
  endpoint: string = 'chain';

  getChainStatistics = async (params: any) =>
    await HttpService.get(`${this.endpoint}/statistics`, params);
}

export default new ChainService();
