import { StyledTableCell } from './styles';

export const EnhancedTableHead = (props: any) => {
  const { columns } = props;

  return (
    <>
      {columns.map((column: any) => (
        <StyledTableCell
          key={column.id}
          style={{ minWidth: column.minWidth }}
          align={column.align}
        >
          {column.label}
        </StyledTableCell>
      ))}
    </>
  );
};
