import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { App } from 'App';
import {
  AuthProvider,
  NotificationProvider,
  CustomThemeProvider,
} from 'contexts';

import './index.css';
import { VerificationProvider } from 'contexts/VerificationContext';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <CustomThemeProvider>
        <NotificationProvider>
          <AuthProvider>
            {/* <VerificationProvider> */}
            <App />
            {/* </VerificationProvider> */}
          </AuthProvider>
        </NotificationProvider>
      </CustomThemeProvider>
    </BrowserRouter>
  </React.StrictMode>,
);
