import { ColumnsType } from 'components/Tables/BaseTable/types';
import moment from 'moment';

export const columns: ColumnsType[] = [
  {
    id: 'type',
    label: 'Type',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'description',
    label: 'Description',
    minWidth: 100,
    align: 'left',
  },
  {
    id: 'date',
    label: 'Date',
    minWidth: 100,
    align: 'left',
    render: ({ date }) => moment.utc(date).format('YYYY-MM-DD HH:mm'),
  },
];
