import HttpService from './http.service';
class HostingService {
  endpoint: string = 'hosting';

  getHostings = async (params: any) =>
    await HttpService.get(`${this.endpoint}`, params);

  getAllHostings = async () => await HttpService.get(`${this.endpoint}/all`);

  createHosting = async (payload: any) =>
    await HttpService.post(`${this.endpoint}`, payload);

  updateHosting = async (hostingId: string, payload: any) =>
    await HttpService.put(`${this.endpoint}/${hostingId}`, payload);

  deleteHosting = async (hostingId: string) =>
    await HttpService.delete(`${this.endpoint}/${hostingId}`);
}

export default new HostingService();
