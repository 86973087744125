import { ColumnsType } from 'components/Tables/BaseTable/types';

export const columns: ColumnsType[] = [
  {
    id: 'fee',
    label: 'Fee',
    minWidth: 100,
    align: 'left',
    render: ({ fee }: any) => `${fee}%`,
  },
  {
    id: 'date',
    label: 'Date',
    minWidth: 100,
    align: 'left',
  },
];
