import HttpService from './http.service';

class EventsService {
  endpoint: string = 'events';

  getEvents = async (params: any) =>
    await HttpService.get(`${this.endpoint}`, params);

  getUserEvents = async (id: any, params: any) =>
    await HttpService.get(`${this.endpoint}/user/${id}`, params);

  createEvent = async (payload: any) =>
    await HttpService.post(`${this.endpoint}/`, payload);

  updateEvent = async (eventsId: string, payload: any) =>
    await HttpService.put(`${this.endpoint}/${eventsId}`, payload);

  deleteEvent = async (eventsId: string) =>
    await HttpService.delete(`${this.endpoint}/${eventsId}`);
}

export default new EventsService();
