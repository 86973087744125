import { Navigate } from 'react-router-dom';
import { userRedirectionMapping } from 'routes/mappings';
import { useAuth } from 'shared/hooks/useAuth';

interface IProps {
  children: JSX.Element;
}

export const AuthRoute = ({ children }: IProps): JSX.Element => {
  const { user } = useAuth();

  return !user ? (
    children
  ) : (
    <Navigate to={userRedirectionMapping[user.role]} replace />
  );
};
