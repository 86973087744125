import { Grid, Box } from '@mui/material';
import { SectionHeading } from 'components/SectionHeading';
import { WalletTransaction } from './WalletTransaction';
import { WalletBalance } from './WalletBalance';
import { useAuth } from 'shared/hooks/useAuth';

export const WalletStatus = (props: any) => {
  const { transactions, wallet, currency, coinAbb } = props;

  const { user } = useAuth();

  return (
    <Box sx={{ marginTop: '50px' }}>
      <Grid container rowSpacing={2} columnSpacing={2}>
        <Grid container rowSpacing={2} columnSpacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <WalletBalance
              wallet={wallet}
              currency={currency}
              coinAbb={coinAbb}
            />
          </Grid>
        </Grid>
        <Grid container>
          <SectionHeading
            sx={{
              color: '#fff',
              margin: '32px 0',
              paddingLeft: '15px',
            }}
            name={`${currency} Transactions`}
          />
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <WalletTransaction transactions={transactions} coinAbb={coinAbb} />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
