import { colors } from 'shared/constants/theme';
import Elipse from 'assets/images/elipse.png';

export const backgroundStyles = {
  height: '100%',
  background: colors.lightGreen,
  backgroundImage: `url(${Elipse})`,
  backgroundSize: '300px 300px',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'right 0 top -50px',
  border: 'unset',
  color: colors.white,
  justifyContent: 'unset',
  display: 'flex',
};
