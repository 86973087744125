import { Grid } from '@mui/material';
import { Events } from './Events';
import { Hashrate } from './Hashrate';

export const Activity = (props: any) => {
  const { hashrateData, currencyValue } = props;
  return (
    <>
      <Grid container rowSpacing={2} columnSpacing={2} marginTop="15px">
        <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
          <Hashrate hashrateData={hashrateData} />
        </Grid>
        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
          <Events currencyValue={currencyValue} />
        </Grid>
      </Grid>
    </>
  );
};
