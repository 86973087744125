import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import moment from 'moment';
import { Box, Button, Typography } from '@mui/material';
import { useNotification } from 'shared/hooks/useNotification';
import { ControlledTextField } from 'components/ControlledInputs/TextField';
import { ControlledDatePicker } from 'components/ControlledInputs/DatePicker';
import FeeService from 'shared/services/fee.service';

const schema = yup.object().shape({
  id: yup.string(),
  fee: yup.number().required('Fee is a required field'),
  date: yup.string().required('Date is a required field'),
});

export const FeeForm = ({
  setShowModal,
  editData,
  onFinish,
}: any): JSX.Element => {
  const { customerId } = useParams();

  const formValues: any = useMemo(
    () =>
      editData
        ? {
            fee: editData.fee,
            date: moment(editData.date).format('MM-YYYY'),
          }
        : {
            fee: null,
            date: null,
          },
    [editData],
  );

  const { showSnackbar } = useNotification();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: formValues,
  });

  const onSubmit = async (fee: any) => {
    const payload: any = {
      ...fee,
      user: +customerId!,
    };

    try {
      if (editData?.id) {
        delete payload?.id;
        await FeeService.updateFee(editData.id, payload);
        showSnackbar('Successfully Edited', 'success');
      } else {
        await FeeService.createFee(payload);
        showSnackbar('Successfully Created', 'success');
      }
      onFinish();
    } catch (err: any) {
      showSnackbar(err.error, 'error');
    } finally {
      setShowModal(false);
    }
  };

  return (
    <>
      <Box
        sx={{
          padding: 5,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <Typography>{editData?.id ? 'Edit' : 'Add'} Fee</Typography>
        <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
          {editData?.id && (
            <ControlledTextField
              disabled
              control={control}
              defaultValue={editData?.id ?? ''}
              name="id"
              label="ID"
              error={errors}
            />
          )}
          <ControlledTextField
            required
            control={control}
            defaultValue={editData?.fee ?? 0}
            name="fee"
            label="Fee"
            error={errors}
            type="number"
          />
          <ControlledDatePicker
            label="Date"
            required
            name="date"
            control={control}
            placeholder="MM/DD/YYYY"
            dateFormat="MM/DD/yyyy"
            error={errors}
          />
          <Button className="submitButton" type="submit">
            {!editData?.id ? 'Add Fee' : 'Edit Fee'}
          </Button>
        </form>
      </Box>
    </>
  );
};
