import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import moment from 'moment';
import { Box, Button, Typography } from '@mui/material';
import { useNotification } from 'shared/hooks/useNotification';
import { ControlledTextField } from 'components/ControlledInputs/TextField';
import { ControlledDatePicker } from 'components/ControlledInputs/DatePicker';
import EventsService from 'shared/services/events.service';
import { ControlledSelect } from 'components/ControlledInputs/Select';
import { handleCurrencyName } from 'shared/utils/currency';
import { Currency } from 'shared/utils/enum/coinEnums';

const schema = yup.object().shape({
  id: yup.string(),
  type: yup.string().required('Type is a required field'),
  date: yup.string().required('Date is a required field'),
  description: yup.string().required('Description is a required field'),
});

const typeOptions = [
  { name: 'Maintenance', value: 'Maintenance' },
  { name: 'Deployment', value: 'Deployment' },
  { name: 'Disruption', value: 'Disruption' },
];

const currencies = Object.values(Currency).map((value) => ({
  value,
  name: handleCurrencyName(value),
}));

export const EventForm = ({
  setShowModal,
  editData,
  onFinish,
  currency,
}: any): JSX.Element => {
  const { customerId } = useParams();

  const formValues: any = useMemo(
    () =>
      editData
        ? {
            type: editData.type,
            description: editData.description,
            date: moment.utc(editData.date).format('YYYY-MM-DD HH:mm'),
          }
        : {
            type: null,
            description: null,
            date: null,
          },
    [editData],
  );

  const selectedCurrency = useMemo(() => {
    return currencies.find((c) => c.value === currency)?.value;
  }, [currency]);

  const { showSnackbar } = useNotification();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: formValues,
  });

  const onSubmit = async (event: any) => {
    const payload: any = {
      ...event,
      user: customerId,
    };

    try {
      if (editData?.id) {
        delete payload?.id;
        await EventsService.updateEvent(editData.id, payload);
        showSnackbar('Successfully Edited', 'success');
      } else {
        await EventsService.createEvent(payload);
        showSnackbar('Successfully Created', 'success');
      }
      onFinish();
    } catch (err: any) {
      showSnackbar(err.error, 'error');
    } finally {
      setShowModal(false);
    }
  };

  return (
    <>
      <Box
        sx={{
          padding: 5,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <Typography>{editData?.id ? 'Edit' : 'Add'} Event</Typography>
        <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
          <Box sx={{ width: '100%' }}>
            <ControlledSelect
              control={control}
              name="currency"
              defaultValue={selectedCurrency}
              label="Cryptocurrency"
              error={errors}
              options={currencies}
              disabled={true}
            />
          </Box>
          <ControlledSelect
            control={control}
            name="type"
            defaultValue={editData?.type ?? ''}
            label="Type"
            error={errors}
            required
            options={typeOptions}
          />
          <ControlledTextField
            required
            control={control}
            defaultValue={editData?.description ?? ''}
            name="description"
            label="Description"
            placeholder="Description"
            error={errors}
          />
          <ControlledDatePicker
            label="Date"
            required
            name="date"
            control={control}
            placeholder="YYYY-MM-DD HH:mm"
            isDateTime
            error={errors}
          />
          <Button className="submitButton" type="submit">
            {!editData?.id ? 'Add event' : 'Edit event'}
          </Button>
        </form>
      </Box>
    </>
  );
};
