import { IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';

export const IconButtonStyled = styled(IconButton)`
  background-color: transparent;
  border: 2px solid #eaeaea;
  border-radius: 4px;
  margin-right: 10px;
  padding: 0;

  &:hover {
    svg {
      color: white;
    }
  }

  & svg {
    color: #1f1f1f;
  }
`;
