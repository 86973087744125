import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { routesMapping } from 'routes/mappings';
import usersService from 'shared/services/users.service';

export const AffiliateCustomerCheck = (props: any) => {
  const [loaded, setLoaded] = useState(false);
  const { children } = props;
  const { customerId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    checkAffiliate();
  }, [customerId]);

  const checkAffiliate = async () => {
    try {
      if (customerId) {
        const data = await usersService.getAffiliateCustomer(customerId);
        if (data) {
          setLoaded(true);
        }
      }
    } catch (err: any) {
      navigate(routesMapping.affiliateOperation);
    }
  };
  return <>{loaded && <>{children}</>}</>;
};
