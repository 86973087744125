import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { colors } from 'shared/constants/theme';

export const SimulationTable = (props: any) => {
  const { rows } = props;
  return (
    <Table
      style={{
        tableLayout: 'fixed',
      }}
    >
      <TableBody>
        {rows.map((row: any) => (
          <TableRow sx={{}}>
            <TableCell
              sx={{
                padding: '5px 10px 5px 10px',
                backgroundColor: '#F3F7FA',
                borderRight: '1px solid #e0e0e0 !important',
              }}
            >
              <Typography
                sx={{
                  fontSize: {
                    xs: '12px',
                    sm: '14px',
                    md: '14px',
                    lg: '14px',
                    xl: '14px',
                  },
                  fontWeight: 500,
                }}
              >
                {row.name}
                {row.asterik ? <sup>*</sup> : <></>}
              </Typography>
            </TableCell>
            <TableCell
              sx={{
                padding: '5px 10px 5px 10px',
                backgroundColor: '#F3F7FA',
              }}
            >
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: {
                    xs: '12px',
                    sm: '14px',
                    md: '14px',
                    lg: '14px',
                    xl: '14px',
                  },
                  color: colors.lightGreen,
                }}
              >
                {row.value}
              </Typography>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export const Preview = (props: any) => {
  const { sectionName, sectionData } = props;

  return (
    <Box width="100%" marginTop={2}>
      <Typography
        sx={{
          width: '100%',
          fontWeight: '500',
          fontSize: '18px',
          marginBottom: 1,
        }}
      >
        {sectionName}
      </Typography>
      <SimulationTable rows={sectionData} />
    </Box>
  );
};
