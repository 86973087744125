import moment from 'moment';
import { Active } from './enum/activeEnums';
import { formatNumberToTerra } from './formatNumberToTerra';
import { PoolSpeedBeanListTypes } from 'components/Charts/HashrateChart/type';
import {
  defaultLineChartStyles,
  setLinearGradientColor,
} from 'components/Charts/styles';
import { colors } from 'shared/constants/theme';

export const createDataForCountriesTable = (
  id: number,
  country: string,
  sessions: number,
  views: number,
) => ({ id, country, sessions, views });

export const createDataForHashrateMeasuredChart = (
  array: PoolSpeedBeanListTypes[],
) => {
  return {
    labels: array.map((elem: PoolSpeedBeanListTypes) => elem?.date),
    datasets: [
      {
        data: array.map((elem: PoolSpeedBeanListTypes) => elem?.speed),
        borderColor: colors.lightGreen,
        fill: true,
        pointBackgroundColor: colors.lightGreen,
        borderWidth: 1.5,
        pointRadius: 0,
        backgroundColor: (context: any) => setLinearGradientColor(context),
      },
    ],
  };
};

export const createOptionsForHashrateMeasuredChart = (
  hashrateMeasuredData: any,
  activeChart: any,
): any => {
  const max = Math.max(...hashrateMeasuredData.datasets[0].data);
  const verticalMaxNumber = max > 1 ? Math.round(max) : Number(max.toFixed(2));
  return {
    elements: defaultLineChartStyles.elements,
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          minRotation: 0,
          maxRotation: 0,
          maxTicksLimit: 7,
          callback(tickValue: any) {
            return moment(hashrateMeasuredData.labels[tickValue]).format(
              activeChart !== Active.HOUR ? 'MMM DD' : 'HH:mm',
            );
          },
        },
      },
      y: {
        min: 0,
        max: verticalMaxNumber * 2,
        ticks: {
          maxTicksLimit: 8,
          callback: (value: any) => {
            return `${formatNumberToTerra(value)} PH/s`;
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        ...defaultLineChartStyles.plugins.tooltip,
        callbacks: {
          title: (item: any) => {
            const label = item[0].label;
            return moment(label).format(
              activeChart !== Active.HOUR ? 'ddd, MMM DD' : 'ddd, MMM DD HH:mm',
            );
          },
          label: (item: any) => `Hashrate ${item.formattedValue} PH/s`,
        },
      },
    },
  };
};
