import { AddOpportunityButton } from './cells';

export const subtableColumns = [
  {
    id: 'supplier',
    label: 'SUPPLIER',
    minWidth: 100,
    align: 'left',
    sortable: false,
    render: (props: any) => {
      return props.supplier.username;
    },
  },
  {
    id: 'hosting',
    label: 'Hosting',
    minWidth: 100,
    sortable: false,
    align: 'left',
    render: (props: any) => {
      return props.hostingOpportunity ? props.hostingOpportunity.name : '-';
    },
  },
  {
    id: 'price',
    label: 'PRICE, $',
    minWidth: 100,
    sortable: false,
    align: 'left',
  },
  {
    id: 'delivery',
    label: 'DELIVERY, $',
    minWidth: 100,
    sortable: false,
    align: 'left',
  },
  {
    id: 'overclocking',
    label: 'OVERCLOCKING, $',
    minWidth: 100,
    sortable: false,
    align: 'left',
  },
  {
    id: 'container',
    label: 'CONTAINER, $',
    minWidth: 100,
    sortable: false,
    align: 'left',
  },
];

export const columns = (
  fetchAccount: any,
  setIsLoading: any,
  hardwareChange: any,
  setHardwareChange: any,
) => {
  return [
    {
      id: 'device',
      label: 'DEVICE',
      minWidth: 100,
      sortable: false,
      align: 'left',
    },
    {
      id: 'coin',
      label: 'Coin',
      minWidth: 100,
      sortable: false,
      align: 'left',
      render: (props: any) => props.currency,
    },
    {
      id: 'hashrate',
      label: 'HASHRATE, TH/S',
      minWidth: 100,
      sortable: false,
      align: 'left',
    },
    {
      id: 'electricity',
      label: 'POWER, WATT',
      minWidth: 100,
      sortable: false,
      align: 'left',
    },
    {
      id: 'addButton',
      label: 'ADD OPPORTUNITY',
      minWidth: 100,
      sortable: false,
      align: 'left',
      render: (props: any) => {
        return (
          <AddOpportunityButton
            hardware={props}
            onFinish={fetchAccount}
            setIsLoading={setIsLoading}
            hardwareChange={hardwareChange}
            setHardwareChange={setHardwareChange}
          />
        );
      },
    },
  ];
};
