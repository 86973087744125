export const modalContainer = {
  display: 'flex',
  flexDirection: 'column',
  padding: '40px',
  alignItems: 'center',
  rowGap: '20px',
};

export const textStyles = {
  textAlign: 'center',
  fontSize: '24px',
  fontWeight: '500',
};
