import HttpService from './http.service';

class HashrateService {
  endpoint: string = 'hashrate';

  getSupplierContractsAverageUptime = async (params: any) =>
    await HttpService.get(`${this.endpoint}/average-uptime`, params);

  getSupplierContractsStatistics = async (params: any) =>
    await HttpService.get(`${this.endpoint}/statistics`, params);
}

export default new HashrateService();
