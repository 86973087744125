import { Box } from '@mui/material';
import { AdminCustomersTable } from 'components/Tables/AdminCustomersTable';

export const Customers = () => {
  return (
    <Box>
      <AdminCustomersTable />
    </Box>
  );
};
