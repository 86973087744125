import moment from 'moment';
import { PdfActions } from 'components/PdfViewer';
import { ColumnsType } from 'components/Tables/BaseTable/types';

export const columns: ColumnsType[] = [
  {
    id: 'number',
    label: 'Number',
    minWidth: 120,
    align: 'left',
    sortable: false,
  },
  {
    id: 'type',
    label: 'Type',
    minWidth: 120,
    align: 'left',
    sortable: false,
  },
  {
    id: 'date',
    label: 'Date',
    minWidth: 100,
    align: 'left',
    sortable: false,
    render: (value) => moment(value.date).format('MM-YYYY'),
  },
  {
    id: 'amount',
    label: 'Total',
    minWidth: 120,
    align: 'left',
    sortable: false,
    render: ({ amount }) =>
      `$${
        isNaN(amount)
          ? 0
          : Number(amount).toLocaleString('en-US', {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })
      }`,
  },
  {
    id: 'status',
    label: 'Status',
    minWidth: 120,
    align: 'left',
    sortable: false,
  },
  {
    label: 'PDF file',
    sortable: false,
    minWidth: 150,
    align: 'left',
    render: (value) => <PdfActions pdfUrl={value.pdf} />,
  },
];
