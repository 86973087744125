import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import { UserRole } from 'shared/utils/enum/UserEnum';
import down from 'assets/images/down.svg';
import up from 'assets/images/up.svg';
import person from 'assets/images/person.svg';
import {
  homeHeaderSettings,
  customerSetting,
  testSetting,
} from 'shared/constants/homeHeader';
import { routesMapping } from 'routes/mappings';
import { useAuth } from 'shared/hooks/useAuth';
import { IconButton, SvgIcon } from '@mui/material';
import { HearstLogo } from 'components/Icons/HearstLogo';
import styles from './styles';
import LayoutNavigateBar from '../LayoutNavigateBar';
import filesService from 'shared/services/files.service';
import { useVerification } from 'shared/hooks/useVerification';
import { LogoLink } from '../LogoLink';

export const Header = () => {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [anchorElMobileMenu, setAnchorElMobileMenu] =
    useState<null | HTMLElement>(null);
  const { user, logout } = useAuth();
  // const { isNewUser, isVerified } = useVerification();
  const navigate = useNavigate();
  const location = useLocation();

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorElUser(event.currentTarget);
  };

  const handleOpenMobileRoutes = (
    event: React.MouseEvent<HTMLElement>,
  ): void => {
    setAnchorElMobileMenu(event.currentTarget);
  };

  const handleCloseMobileRoutes = () => setAnchorElMobileMenu(null);

  const handleCloseUserMenu = (setting: string): void => {
    switch (setting) {
      case 'Log Out':
        logout();
        break;
      case 'Sub Account':
        navigate(routesMapping.subAccount);
        break;
      case 'Profile':
        navigate(routesMapping.profile);
        break;
      case 'Verification':
        navigate(routesMapping.verification);
        break;
      default:
        setAnchorElUser(null);
        return;
    }
    setAnchorElUser(null);
  };

  const options = homeHeaderSettings;

  return (
    <AppBar position="static" elevation={0} sx={styles.appBar}>
      <Container sx={styles.container}>
        <Toolbar disableGutters>
          <Box sx={styles.headerContainer}>
            {/* {location.pathname === routesMapping.verification &&
            isNewUser &&
            !isVerified ? (
              <> </>
            ) : (
              <>
                <IconButton
                  onClick={handleOpenMobileRoutes}
                  sx={styles.mobileMenuButton}
                >
                  <MenuIcon
                    sx={{
                      color: 'white',
                    }}
                  />
                </IconButton>
                <Menu
                  sx={styles.mobileMenuInnerPaper}
                  anchorEl={anchorElMobileMenu}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  open={Boolean(anchorElMobileMenu)}
                  onClose={handleCloseMobileRoutes}
                >
                  <LayoutNavigateBar />
                </Menu>
              </>
            )} */}
            <>
              <IconButton
                onClick={handleOpenMobileRoutes}
                sx={styles.mobileMenuButton}
              >
                <MenuIcon
                  sx={{
                    color: 'white',
                  }}
                />
              </IconButton>
              <Menu
                sx={styles.mobileMenuInnerPaper}
                anchorEl={anchorElMobileMenu}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElMobileMenu)}
                onClose={handleCloseMobileRoutes}
              >
                <LayoutNavigateBar />
              </Menu>
            </>
            <Box sx={styles.headerLeftContainer}>
              <LogoLink />
              <Box display="flex" columnGap={2} sx={styles.tabs}>
                <LayoutNavigateBar />
              </Box>
            </Box>
            <Box>
              <Box
                sx={styles.userMenuOuterContainer}
                onClick={handleOpenUserMenu}
              >
                <Box sx={styles.userMenuInnerContainer}>
                  <Avatar
                    sx={styles.avatar}
                    src={
                      user.image ? filesService.getFileUrl(user.image) : person
                    }
                  />
                  <Box sx={styles.userMenuInfoContainer}>
                    <Typography color="white">
                      {user.firstName && user.lastName
                        ? `${user.firstName}${user.lastName}`
                        : user.username}
                    </Typography>
                    <Typography color="#BDBDBD" fontSize={12} fontWeight={300}>
                      {user.email}
                    </Typography>
                  </Box>
                  <img
                    style={{ marginLeft: 20 }}
                    src={anchorElUser ? up : down}
                    alt=""
                  />
                </Box>
              </Box>
              <Menu
                sx={styles.userMenuInnerPaper}
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                <Typography color="black" sx={styles.menuHiddenContent}>
                  {user.firstName && user.lastName
                    ? `${user.firstName}${user.lastName}`
                    : user.username}
                </Typography>
                <Typography
                  color="black"
                  fontSize={12}
                  fontWeight={300}
                  sx={styles.menuHiddenContent}
                >
                  {user.email}
                </Typography>
                {options.map((setting, index) => (
                  <MenuItem
                    disableRipple
                    sx={styles.userMenu(index, options)}
                    key={setting.name}
                    onClick={() => {
                      handleCloseUserMenu(setting.name);
                    }}
                    disabled={setting.disabled}
                  >
                    <SvgIcon
                      sx={{
                        stroke: '#000 !important',
                      }}
                    >
                      <setting.icon />
                    </SvgIcon>
                    <Typography textAlign="center" color="#000 !important">
                      {setting.name}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
