import { useMemo } from 'react';
import { Box } from '@mui/material';
import { RedesignedTable } from '../../RedesignedTable';
import { columns } from './columns';

export const ProtocolLiquidityPoolTable = (props: any) => {
  const { elements, usdFarmingValue } = props;

  const tableColumns = useMemo(
    () => columns(usdFarmingValue),
    [usdFarmingValue],
  );

  return (
    <Box sx={{ marginTop: '20px' }}>
      <RedesignedTable
        items={elements}
        columns={tableColumns}
        paginated={false}
        wrapped={false}
      />
    </Box>
  );
};
