import { useMemo } from 'react';
import { Line } from 'react-chartjs-2';
import { Active } from 'shared/utils/enum/activeEnums';
import { Skeleton } from '@mui/material';
import {
  createDataForHashrateMeasuredChart,
  createOptionsForHashrateMeasuredChart,
} from 'shared/utils/createData';
import { linePlugin } from '../plugins';

export const HashrateChart = (props: any) => {
  const { hashrateData, activeChart } = props;

  const hashrateMeasuredData = useMemo(() => {
    const speedList =
      hashrateData?.[activeChart === Active.DAY ? 'dailyData' : 'hourlyData'] ||
      [];

    return createDataForHashrateMeasuredChart(speedList);
  }, [hashrateData, activeChart]);

  return (
    <>
      {hashrateData ? (
        <Line
          options={{
            ...createOptionsForHashrateMeasuredChart(
              hashrateMeasuredData,
              activeChart,
            ),
            interaction: {
              mode: 'index',
              intersect: false,
            },
          }}
          data={hashrateMeasuredData}
          plugins={[linePlugin]}
        />
      ) : (
        <Skeleton variant="rectangular" height="100%" />
      )}
    </>
  );
};
