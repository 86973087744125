import HttpService from './http.service';

class BitcoinService {
  endpoint: string = 'bitcoin';

  getBitcoinData = async (params: any) =>
    await HttpService.get(this.endpoint, params);

  getDateFilteredBitcoinChart = async (params: any) =>
    await HttpService.get(`${this.endpoint}/date`, params);

  getBitcoinStatistics = async (params: any) =>
    await HttpService.get(`${this.endpoint}/statistics`, params);

  getWalletData = async (params: any) =>
    await HttpService.get(`${this.endpoint}/wallet`, params);
}

export default new BitcoinService();
