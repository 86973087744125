import HttpService from './http.service';
class HardwareService {
  endpoint: string = 'hardware-opportunity';

  getHardwareOppotunities = async (params: any) =>
    await HttpService.get(`${this.endpoint}`, params);

  createHardwareOpportunity = async (payload: any) =>
    await HttpService.post(`${this.endpoint}`, payload);

  updateHardwareOpportunity = async (hostingId: string, payload: any) =>
    await HttpService.put(`${this.endpoint}/${hostingId}`, payload);

  deleteHardwareOpportunity = async (hostingId: string) =>
    await HttpService.delete(`${this.endpoint}/${hostingId}`);
}

export default new HardwareService();
