import { ColumnsType } from 'components/Tables/BaseTable/types';
import { UserRole } from 'shared/utils/enum/UserEnum';
import { Actions } from '../Actions';

export const getColumns = ({
  handleEdit,
  onRowDelete,
  role,
}: any): ColumnsType[] => [
  ...(role === UserRole.AFFILIATE
    ? [
        {
          id: 'actions',
          label: 'Actions',
          minWidth: 200,
          align: 'left',
          render: (row: any) => (
            <Actions
              row={row}
              handleEdit={handleEdit}
              onRowDelete={onRowDelete}
            />
          ),
        },
      ]
    : []),
  {
    id: 'username',
    label: 'Full Name',
    minWidth: 100,
  },
  {
    id: 'email',
    label: 'Email Address',
    minWidth: 100,
  },
  {
    id: 'affiliatePercent',
    label: 'Upfront %',
    minWidth: 100,
    render: ({ affiliatePercent }) => `${affiliatePercent}%`,
  },
  {
    id: 'hashratePercent',
    label: 'Hashrate %',
    minWidth: 100,
    render: ({ hashratePercent }) => `${hashratePercent}%`,
  },
];
